import { Container } from '@mui/material';
import { Route, Switch } from 'react-router-dom';

import OffersList from './offers-list';

import Casino888 from '../../pages/888';
import AmazonSlots from '../../pages/amazon-slots';
import BetNeptune from '../../pages/betneptune';
import Cashmo from '../../pages/cashmo';
import FortuneJackpots from '../../pages/fortune-jackpots';
import FoxyGames from '../../pages/foxy-games';
import GalaBingo from '../../pages/gala-bingo';
import GalaSpins from '../../pages/gala-spins';
import MayfairCasino from '../../pages/mayfaircasino';
import MiamiJackpots from '../../pages/miami-jackpots';
import MrSpin from '../../pages/mrspin';
import PartyCasino from '../../pages/partycasino';

import Page404 from '../../pages/page-404';

const Routes = () => {
	const linkCompose = id => `http://trk.vip-media.net/aff_c?offer_id=${id}&aff_id=12081`;

	const hrefs = {
		Casino888: linkCompose(630),
		// AmazonSlots: linkCompose(656),
		AmazonSlots: linkCompose(552),
		BetNeptune: linkCompose(667),
		Cashmo: linkCompose(602),
		FortuneJackpots: linkCompose(600),
		MayfairCasino: linkCompose(645),
		MiamiJackpots: linkCompose(666),
		MrSpin: linkCompose(601),
		PartyCasino: linkCompose(629),
		FoxyGames: linkCompose(703)
	};

	return (
		<Switch>
			<Route path='/888' exact>
				<Casino888 href={hrefs.Casino888} />
			</Route>
			<Route path='/amazon-slots' exact>
				<AmazonSlots href={hrefs.AmazonSlots} />
			</Route>
			<Route path='/betneptune' exact>
				<BetNeptune href={hrefs.BetNeptune} />
			</Route>
			<Route path='/cashmo' exact>
				<Cashmo href={hrefs.Cashmo} />
			</Route>
			<Route path='/fortune-jackpots' exact>
				<FortuneJackpots href={hrefs.FortuneJackpots} />
			</Route>
			<Route path='/mayfaircasino' exact>
				<MayfairCasino href={hrefs.MayfairCasino} />
			</Route>
			<Route path='/miami-jackpots' exact>
				<MiamiJackpots href={hrefs.MiamiJackpots} />
			</Route>
			<Route path='/mrspin' exact>
				<MrSpin href={hrefs.MrSpin} />
			</Route>
			<Route path='/partycasino' exact>
				<PartyCasino href={hrefs.PartyCasino} />
			</Route>
			<Route path='/gala-spins' exact>
				<GalaSpins href='http://trk.vip-media.net/aff_c?offer_id=557&aff_id=12093' />
			</Route>
			<Route path='/foxy-games' exact>
				<FoxyGames href={hrefs.FoxyGames} />
			</Route>
			<Route path='/gala-spins-2' exact>
				<GalaSpins href='https://trk.vip-media.net/aff_c?offer_id=557&aff_id=12115' />
			</Route>
			<Route path='/gala-spins-3' exact>
				<GalaSpins href='https://trk.vip-media.net/aff_c?offer_id=557&aff_id=12103' />
			</Route>
			<Route path='/gala-bingo' exact>
				<GalaBingo href='https://trk.vip-media.net/aff_c?offer_id=784&aff_id=12103' />
			</Route>
			<Route path='/gala-bingo-2' exact>
				<GalaBingo href='https://trk.vip-media.net/aff_c?offer_id=784&aff_id=12115' />
			</Route>
			<Route path='/' exact>
				<Container>
					<h1>Offers list</h1>
					<OffersList />
				</Container>
			</Route>
			<Route path='*'>
				<Page404 />
			</Route>
		</Switch>
	);
};

export default Routes;

import { Container } from '@mui/material';

import './MainBar.scss';

const MainBar = () => {
	return (
		<div className='mainbar'>
			<Container>
				<a href='https://www.whichcasinos.co.uk/'>
					<img src='https://www.whichcasinos.co.uk/wp-content/uploads/2019/10/whichcasino_logo.png' alt='logo' />
				</a>
			</Container>
		</div>
	);
};

export default MainBar;

import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>
			<p>
				<strong>
					The Welcome Deposit offer is only available to valid new account holders aged 18 or over making their
					first 3 deposits with us.
				</strong>
			</p>
			<p>
				<strong>The following deposit matches are available:</strong>
			</p>
			<ol>
				<li>1st deposit - 100% match up to £100 in bonus funds &amp; 100 spins on Starburst</li>

				<li>
					2nd deposit - 50% match bonus up to £100 in bonus funds &amp; 50 spins on Finn and the Swirly Spin or
					Finn's Golden Tavern
				</li>

				<li>3rd deposit - 50% match bonus up to £200 in bonus funds &amp; 25 spins on Starburst</li>

				<li>
					To be eligible to claim each stage of the Welcome bonus, players must deposit a minimum of £10 for each
					of the three deposit matches.v
				</li>

				<li>
					Bonus funds carry a wagering requirement of 30x the sum of your deposit and bonus amount (e.g. £10
					deposit + £10 bonus x30 = £600), which must be met before the bonus and any winnings are converted to
					capped cash, and this and any further winnings become withdrawable.
				</li>

				<li>
					The maximum conversion amount from bonus funds, including free spin bonus funds, will be capped at 4x the
					initial bonus amount awarded. Bonus funds convert automatically once wagering is complete.
				</li>

				<li>
					Once credited your match bonus remains valid for 28 days. Should you not complete the wagering
					requirements in this period, remaining bonus funds will be removed from your account.
				</li>

				<li>
					Spins will be activated when you launch Starburst, Finn and the Swirly Spin or Finn's Golden Tavern, and
					are valid for 7 days.
				</li>

				<li>
					These Spins will be worth 10p and are valid on Starburst, Finn and the Swirly Spin or Finn's Golden
					Tavern only.
				</li>

				<li>
					All winnings earned from the spins will be rewarded in bonus funds and carry a wagering requirement of
					30x the total bonus amount earned. For example, free spin winnings of £10 would have a wagering
					requirement of £300 before converting into cash, up to a maximum of 4x the bonus amount awarded.
				</li>

				<li>
					Withdrawal of funds before the wagering is complete will void all bonuses and any winnings of these
					attributable to the account.
				</li>

				<li>
					Only bets placed using bonus funds count towards the wagering requirements of the bonus. Cash funds are
					always wagered with before bonus funds.
				</li>

				<li>
					Wagers on Blackjack and Roulette do not contribute towards the reduction of the wagering requirement of
					bonus balances.
				</li>

				<li>Please, see the list of games excluded from bonus wagering by going to our FAQ link on the sites.</li>

				<li>
					Only one bonus can be active at a time. Please go to "my profile" section to view your entire bonus
					queue.
				</li>

				<li>
					Where any term of the offer or promotion is breached or there is any evidence of a series of bets placed
					by a customer or group of customers, which due to a deposit bonus, enhanced payments, free bets or any
					other promotional offer results in guaranteed customer profits irrespective of the outcome, whether
					individually or as part of a group management reserves the right to reclaim the bonus element of such
					bets. We further reserve the right to ask any customer to provide sufficient documentation for us to be
					satisfied in our absolute discretion as to the customer's identity prior to us crediting any bonus, free
					bet or offer to their account.
				</li>

				<li>
					All such offers are intended for recreational players and Management may in its sole discretion limit the
					eligibility of customers to participate in all or part of any promotion.
				</li>

				<li>
					Management reserves the right to cancel, terminate or alter any competition or promotion (or the rules
					thereof) at any time and without prior notification
				</li>

				<li>
					You may opt-out of receiving promotions and associated promotional communications by contacting customer
					support.
				</li>

				<li>
					Employees, officers and directors of Grace Media Limited, its promotional or other agencies, licensees
					and licensors, service providers and any other associated or affiliated companies shall not be eligible
					for the promotion. The same terms shall apply to the direct families of such persons.
				</li>

				<li>Standard Terms &amp; Conditions apply.</li>

				<li>Management decision is final in all cases.</li>
			</ol>
		</div>
	);
};

export default Content;

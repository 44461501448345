import { Box, Button, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import Banner from '../../banner/Banner';
import Content from './content';

import bannerMobile from './images/banner-mobile.jpg';
import banner from './images/banner.jpg';

import '../../../variables.scss';
import './casino-888.scss';

const Casino888 = ({ href }) => {
	return (
		<>
			<Helmet>
				<title>Casino 888 | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner} bannerMobile={bannerMobile}>
				<p className='big text-yellow'>DOUBLE YOUR 1ST DEPOSIT</p>
				<p className='big'>with up to</p>
				<p className='huge text-yellow'>£100</p>
				<p className='bigger'>welcome bonus</p>
				<Button href={href} variant='contained' size='large' className='yellow text-grey' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-yellow'>Register</span>
							<span className='step-desc text-white'>Create an Account</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-yellow'>Claim</span>
							<span className='step-desc text-white'>Your Bonus</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-yellow'>Enjoy</span>
							<span className='step-desc text-white'>Our #1 Online Casino Games</span>
						</Box>
					</Grid>
				</Grid>
				<Content />
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='yellow text-grey' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</>
	);
};
export default Casino888;

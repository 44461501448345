import FooterLogos from './footer-logos';
import { Container } from '@mui/material';
import './Footer.scss';

const Footer = () => {
	return (
		<footer className='page-footer'>
			<Container>
				<FooterLogos />
			</Container>
			<Container className='warn'>
				Please play responsibly - for more information visit{' '}
				<strong>
					<a
						style={{ color: '#fff', textDecoration: 'underline' }}
						href='http://www.begambleaware.org'
						target='_blank'
						rel='noreferrer'
					>
						www.begambleaware.org
					</a>
				</strong>
			</Container>
			<div className='copyright'>
				<Container>WhichCasinos - © Copyright {new Date().getFullYear()}. All rights reserved.</Container>
			</div>
		</footer>
	);
};

export default Footer;

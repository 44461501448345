import { Box, Container } from '@mui/material';

import './Banner.scss';

const Banner = ({ banner, bannerMobile = null, noShadow = false, children }) => {
	const backgroundImage = bannerMobile ? { xs: `url(${bannerMobile})`, sm: `url(${banner})` } : `url(${banner})`;

	return (
		<Box
			className='banner'
			sx={{
				backgroundImage
			}}
		>
			<Container className={`banner-text${noShadow && ' no-shadow'}`} sx={{ height: '100%', py: 2 }}>
				{children}
			</Container>
		</Box>
	);
};

export default Banner;

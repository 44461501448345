import plus18 from './images/18plus.svg';
import gamstop from './images/gamstop.svg';
import gamcare from './images/gamcare.svg';
import tttt from './images/tttt.svg';
import begambleaware from './images/begambleaware.svg';
import whichcasinos from './images/whichcasinos.png';
import { Grid } from '@mui/material';

import './FooterLogos.scss';

const FooterLogos = () => {
	const logosData = [
		{ src: plus18, alt: '18 plus only', link: null, target: null },
		{
			src: whichcasinos,
			alt: 'Home Page',
			link: 'https://www.whichcasinos.co.uk',
			target: '_self'
		},
		{
			src: begambleaware,
			alt: 'BeGambleAware',
			link: 'http://www.gambleaware.co.uk',
			target: '_blank'
		},
		{
			src: gamstop,
			alt: 'GamStop',
			link: 'https://www.gamstop.co.uk',
			target: '_blank'
		},
		{
			src: gamcare,
			alt: 'GamCare',
			link: 'https://www.gamcare.org.uk/',
			target: '_blank'
		},
		{
			src: tttt,
			alt: 'Take Time To Think',
			link: 'https://www.taketimetothink.co.uk/',
			target: '_blank'
		}
	];
	const logos = logosData.map((item, i) => {
		if (item.link) {
			return (
				<Grid item xs={12} md={3} key={i}>
					<a href={item.link} target={item.target}>
						<img src={item.src} alt={item.alt} className='logo-img' />
					</a>
				</Grid>
			);
		} else {
			return (
				<Grid item xs={12} md={3} key={i}>
					<img src={item.src} alt={item.alt} className='logo-img' />
				</Grid>
			);
		}
	});
	return (
		<Grid
			container
			spacing={2}
			className='logos'
			sx={{ alignItems: 'center', justifyContent: 'center', padding: '1em' }}
		>
			{logos}
		</Grid>
	);
};

export default FooterLogos;

import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.png';

import '../../../variables.scss';
import './mayfaircasino.scss';

const MayfairCasino = ({ href }) => {
	return (
		<div className='mayfair'>
			<Helmet>
				<title>Mayfair Casino | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='big'>WELCOME OFFER</p>
				<p className='text-orange bigger'>Up to £400 Welcome Offer</p>
				<p className='big'>+ 175 SPINS ON SELECTED SLOTS</p>
				<Button
					href={href}
					variant='contained'
					size='large'
					className='orange text-white'
					style={{ marginTop: 10 }}
				>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New players only. First 3 deposits only. Min deposit £10, max total bonus £400 and 175 spins. 30x
						bonus wagering (deposit + bonus), 30x spins wagering, 4x conversion. Selected slots only. Full T&Cs
						apply. BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='orange text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default MayfairCasino;

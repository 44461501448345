import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.png';

import '../../../variables.scss';
import './betneptune.scss';

const BetNeptune = ({ href }) => {
	return (
		<div className='betneptune'>
			<Helmet>
				<title>BetNeptune | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='big'>WELCOME PACKAGE</p>
				<p className='bigger'>UP TO £/€/$ 300 + 50 FREE SPINS</p>
				<p className='big'>on Gonzo's Quest & Reactoonz</p>
				<Button href={href} variant='contained' size='large' className='green text-white' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>SIGN UP</span>
							<span className='step-desc text-white'>AT BET NEPTUNE</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>ACTIVATE YOUR ACCOUNT</span>
							<span className='step-desc text-white'>FROM YOUR REGISTERED EMAIL</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>DEPOSIT AND GET OUR WELCOME PACKAGE</span>
							<span className='step-desc text-white'>
								UP TO £/€/$ 300 + 50 FREE SPINS on Gonzo's Quest & Reactoonz
							</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New Players Only. Wagering occurs from real balance first. 50x wagering the bonus or any winnings
						generated from free spins, contribution may vary per game. The wagering requirement is calculated on
						bonus bets only. Bonus is valid for 30 Days/ free spins valid for 7 days from issue. Max conversion: 3
						times the bonus amount or from free spins: $/£/€ 20. Excluded Skrill deposits. T&Cs apply.
						BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='green text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default BetNeptune;

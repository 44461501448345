import { Container } from '@mui/material';

const Page404 = () => {
	return (
		<Container>
			<h2>Oops. Such page does not exist.</h2>
		</Container>
	);
};

export default Page404;

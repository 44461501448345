import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>
			<p>
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Bonus Policy
				</a>
				&nbsp;and&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/terms/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Terms of Service
				</a>
				&nbsp;apply.
				<br />
				when you open new account, you have the option of not receiving the [BrandName].com welcome bonus, by
				clicking ‘later’ at the end of registration process. When you choose the option of receiving the [BrandName]
				welcome bonus, you are subject to the&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					bonus policy
				</a>
				.
				<br />
				New depositing players only.
				<br />
				Min. deposit: £20.
				<br />
				Max. bonus: £200.
			</p>
			<p>Welcome Package split over 3 deposits on different days.</p>
			<ul>
				<li>1st Deposit – 100% up to £50 + 20 spins on Starburst min.20</li>
				<li>2nd Deposit – 50% up to £50 + 40 spins on Book Of Dead min.20</li>
				<li>3rd Deposit – 50% up to £100 + 40 spins on Starburst min.20</li>
			</ul>
			<p>
				Wagering Requirement: 35x. Spins expire after 24 hours. Spins credited in specific games.
				<br />
				Offer only applies to new UK players.
				<br />
				In addition to deposit bonus, you are also eligible for 20 spins that will be issued upon your first
				deposit.
				<br />
				Winnings won with games that require deposit, have to be wagered 35x.
				<br />
				Bonuses that require deposit, have to be wagered 35x.
			</p>
			<p>
				[BrandName] Welcome Bonus – 100% bonus on your first and 59% on your second and third deposit up to £50 each
				unless otherwise stated. This bonus only applies for deposits of £20 or higher! All you need to do is just
				deposit the money in your [BrandName] account and you will receive this bonus instantly!
				<br />
				For example:
				<br />
				Deposit £20 get £20 free, play with £40.
				<br />
				Deposit £25 get £25 free, play with £50.
			</p>
			<p>
				The “Welcome bonus” terms &amp; conditions:
				<br />
				o&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Bonus Policy
				</a>
				&nbsp;and&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/terms/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Terms of Service
				</a>
				&nbsp;apply.
				<br />
				o The “Welcome bonus” is limited to one bonus per household.
				<br />
				o The “Welcome bonus” may not be used in conjunction with any other bonus, promotion or offer.
				<br />
				o The “Welcome bonus” must be wagered at [BrandName].com within 21 days of being credited to your account.
				[BrandName].com reserves the right, at any time, to revoke any welcome bonus not used within the allotted
				time period.
				<br />o Please note that in the interests of fair gaming, we require you to have wagered a minimum of 35
				times the sum of your welcome bonus before cashing out any winnings.&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Wagering requirements T&amp;Cs apply.(
					<u>see here</u>)
				</a>
				<br />
				For example:
			</p>
			<p>
				Deposit £20 get £20 free, wager is £700 to cash out.
				<br />
				Deposit £25 get £25 free, wager is £875 to cash out.
			</p>
			<p>
				o Unless stated otherwise by [BrandName].com, the non-deposit bonus is available to new players only
				(players who have not received this bonus before).
				<br />
				Our welcome bonus is automatic. However, there are various reasons for not receiving it and you may need to
				personally request it. If you have not received your automatic welcome bonus after making an approved
				deposit, please contact our support team. This can be done online while you are playing (click the “online
				support” button), or by emailing support@[BrandName].com. Just remember to state your username.
			</p>
			<p>
				Free Games bonus:
				<br />
				o&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Bonus Policy
				</a>
				&nbsp;and&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/terms/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Terms of Service
				</a>
				&nbsp;apply.
				<br />
				o All free games credited to a player’s account must be used within 1 day from when the offer was granted.
				Unused free games will be removed from the account thereafter.
				<br />
				o Free games are considered as a bonus and must be wagered before any winnings can be cashed out. The free
				games bonus is calculated as the total value of the winnings received through the use of the free games. The
				total value of such credit shall be subject to the wagering requirements set out below.
				<br />
				o Any and all free games bonus(es) shall be subject to the standard bonus policy detailed hereby.
				<br />
				o Players, who initiate a deposit in order to receive free games as part of a deposit offer, MUST wager
				their deposit amount before initiating a withdrawal. Players who withdraw their deposit without wagering
				will lose the free games earned from the deposit or if used, any winnings earned from the free games.
				<br />o The above terms also apply to 20 FREE games ON YOUR FIRST DEPOSIT. For more details, please see our
				FAQ page.
			</p>
			<p>
				Deposits may be withdrawn before a player’s wagering requirements have been fulfilled. However, if this
				occurs, all bonuses and winnings will be voided/removed from the player’s account. Players must contact
				support in order to withdraw their deposit.
				<br />
				Bets on different games contribute differently to the compliance of the wagering requirements:&nbsp;
				<a
					href='http://download.gamesrv1.com/Resources/External/bonusterms/tc_eng_gbp.html?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Wagering
				</a>
				,&nbsp;
				<a
					href='https://fj.gamingaffiliates.com/bonus-policy/?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					Bonus Policy
				</a>
				.
			</p>
			<p>
				Winnings received through the use of a free bonus (no deposit) or free spins shall not exceed £100. Any
				winnings which shall be made with the free bonus or free spins which exceeds the £100 maximum, shall be
				considered as nil, and shall not be recorded as winnings. Only the maximum winnings of £100 can be withdrawn
				by the Player subject to the terms specified in the Bonus Policy.
			</p>
			<p>
				When a bet abuses the betting system – regardless of which game it was placed on – it will not count towards
				a player’s wagering requirements. If the player placed single bets equal to or greater than £4 or £0.50 per
				line, or 15 percent of the bonus amount before the wagering requirements for the bonus have been met, we
				reserve the right to withhold any amount in excess of the player’s original deposit from a player’s
				withdrawal. In addition, if a player deposited with Skrill or Neteller and placed single bets equal to or
				greater than £2.50 of the bonus amount before the wagering requirements for the bonus have been met, we
				reserve the right to withhold any amount in excess of the player’s original deposit from a player’s
				withdrawal. If the player placed bets on any of the games which contribute 0% to the&nbsp;
				<a
					href='http://download.gamesrv1.com/Resources/External/bonusterms/tc_eng_gbp.html?AR=AFF&amp;PAR=557468'
					target='_blank'
					rel='noopener noreferrer'
				>
					wagering requirement
				</a>
				&nbsp;before the wagering requirements for the bonus have been met, we reserve the right to withhold any
				amount in excess of a player’s original deposit from a player’s withdrawal.
			</p>
			<p>
				[BrandName] may cancel, amend or modify any promotion, competition, bonus or special offer by publishing the
				amended or modified terms or notice of cancellation on the relevant internet page of the promotion,
				competition or special offer. Such amendment, modification or cancellation shall take effect following the
				expiry of a period of 14 days of such publication, or with immediate effect, where required for security
				reasons, to comply with applicable law or regulation, due to abuse or otherwise where the promotion,
				competition or special offer is not capable of being conducted as specified as a result of exceptional
				circumstances.
			</p>
			<p>
				Please note that [BrandName] will not exercise this right to amend, modify or cancel any promotion,
				competition of special offer under this term for players who have already opted into the relevant promotion,
				competition or special offer, made a deposit in expectation of receipt of a bonus or otherwise commenced
				play in relation to the relevant promotion, competition or special offer where to do so would affect the
				ability of these players to:
				<br />
				• receive or accrue the relevant bonus;
				<br />
				• fulfill the remaining qualifying criteria for the relevant promotion, competition or special offer;
				<br />• use, access or retain any bonuses already received or accrued,
			</p>
			<p>
				except where the relevant Customer(s) has engaged in fraudulent or unlawful behaviour or where the
				amendment, modification or cancellation of the promotion is required to enable Us to comply with our legal
				and/or regulatory obligations. Notwithstanding anything else in this Bonus Policy or any additional terms or
				rules which govern the provision of any gambling promotion to players, players shall not be prevented from
				withdrawing:
				<br />
				• their deposit balance,
				<br />
				• winnings from wagers made with funds in that deposit balance; and
				<br />• winnings from any bonus which are not subject to wagering requirements or restrictions (or for which
				wagering requirements or restrictions have been satisfied),
			</p>
			<p>
				except where [BrandName] is required to prevent such withdrawal of funds in order to comply with legal and
				regulatory obligations (including anti-money laundering requirements).
			</p>
		</div>
	);
};

export default Content;

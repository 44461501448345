import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

const TermsContent = () => {
	return (
		<div>
			<p>
				<strong>IN TOUCH GAMES LIMITED</strong>
			</p>
			<p>
				<strong>CASHMO TERMS AND CONDITIONS</strong>
			</p>
			<p>
				These general terms and conditions ("
				<strong>Terms and Conditions</strong>
				") are effective from&nbsp;
				<strong>5th October 2021</strong>. All previous terms and conditions are cancelled and replaced with these
				Terms and Conditions.
			</p>
			<p>Significant Conditions:</p>
			<ul>
				<li>
					<strong>
						We operate a fixed fee of £3 for any individual withdrawal under £10. Please see section 9 for more
						details.
					</strong>
				</li>
				<li>
					<strong>
						Any money deposited by you will go into your Cashable Credit Account. Any amounts you win will go into
						your Cashable Credit Account.&nbsp; You may withdraw amounts from your Cashable Credit Account,
						subject to the fixed fee of £3 for any individual withdrawal under £10.
					</strong>
				</li>
				<li>
					<strong>
						Any bonuses you are entitled to will go into your Bonus Credit Account. You may not withdraw from your
						Bonus Credit Account.
					</strong>
				</li>
				<li>
					<strong>
						Any bonuses awarded to you (including winnings from promotional free spins) have a 40 x wagering
						requirement. You can withdraw winnings from these bonuses once you have met the relevant Bonus Credit
						Wagering Requirement.
					</strong>
				</li>
				<li>
					<strong>Stakes from your Account will be made in this order:</strong>
					<ul>
						<li>
							<strong>free spins</strong>&nbsp;(if any);
						</li>
						<li>
							any amounts in your Cashable Credit Account which comprise of&nbsp;
							<strong>winnings from bonus credit</strong>;
						</li>
						<li>
							any amounts in your Cashable Credit Account which comprise of&nbsp;
							<strong>winnings from deposits</strong>
						</li>
						<li>
							any&nbsp;
							<strong>deposited amounts in your Cashable Credit Account</strong>; and
						</li>
						<li>
							any&nbsp;
							<strong>bonus amounts in your Bonus Credit Account</strong>.
						</li>
					</ul>
				</li>
				<li>
					<strong>Other important terms and conditions are displayed in bold type.</strong>
				</li>
			</ul>
			<h4>Section 1: Definitions</h4>
			<p>In these Terms and Conditions the following definitions have the following meanings:</p>
			<p>
				"<strong>bet / wager / stake</strong>" – any amount risked by the Player on any game.
			</p>
			<p>
				"<strong>Account</strong>" – a player account created by you using your personal details to enable you to
				play any of our games.
			</p>
			<p>
				<strong>"Bonus Credit Wagering Requirement"&nbsp;</strong>-<strong>&nbsp;</strong>
				the number of times bonus credit must be wagered before winnings from the bonuses can be withdrawn.&nbsp;Any
				bonuses awarded to you (including winnings from promotional free spins) have a 40 x wagering
				requirement.&nbsp; You can withdraw winnings from these bonuses once you have met the relevant Bonus Credit
				Wagering Requirement.
			</p>
			<p>
				<strong>"Bonus Credit Wagering Requirement Balance"</strong>
				&nbsp;- the progress made through the Bonus Credit Wagering Requirement expressed in financial terms.
			</p>
			<p>
				"<strong>Software</strong>" – the software licensed by or to us, including any program or data file or any
				other content derived there from, that is required to be downloaded, accessed or otherwise utilised by you
				from the Website or your mobile device enabling you to use our betting and gaming products.
			</p>
			<p>
				"<strong>you</strong>" – the user of our betting and gaming products; also referred to as the "
				<strong>Player</strong>
				".
			</p>
			<p>
				"<strong>us/we/ours</strong>" – In Touch Games Limited (company number
				<strong>04629082</strong>) and with its registered office at Fountain House, Great Cornbow, Halesowen, West
				Midlands, B63 3BL. In Touch Games Limited is licensed and regulated in Great Britain by the Gambling
				Commission under account number 2091.
			</p>
			<p>
				"<strong>Website</strong>" –&nbsp;
				<a href='https://www.cashmo.co.uk' target='_blank' rel='noreferrer'>
					www.cashmo.co.uk
				</a>
				.
			</p>
			<h4>Section 2: Introduction</h4>
			<ol>
				<li>
					We offer a variety of slot machine, casino and bingo games ("betting and gaming products"). These Terms
					and Conditions apply to all of our Cashmo betting and gaming products. Please read these Terms and
					Conditions carefully. If you do not accept these Terms and Conditions in full, you should not use our
					betting and gaming products on the Website or via mobile devices or tablets, including downloadable
					applications to a mobile.
				</li>
				<li>
					By opening an Account, playing any game or making any deposit on one of our betting and gaming products,
					you accept and agree to be bound by these Terms and Conditions, along with the rules which apply to the
					applicable products available (the "<strong>Rules</strong>" - see paragraph 30 below for further
					explanation) and our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/privacy-policy/' target='_blank' rel='noreferrer'>
						Privacy Policy
					</a>
					.
				</li>
				<li>
					You should not play our games, open, use or reuse an Account, enter the Website or an application, nor
					accept any prize or winnings if you do not agree to be bound by these Terms and Conditions, or any such
					Rules as may be amended from time to time.
				</li>
				<li>
					We will only use your personal information in accordance with our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/privacy-policy/' target='_blank' rel='noreferrer'>
						Privacy Policy
					</a>
					&nbsp;and we reserve the right to amend the terms of such at any time.
				</li>
				<li>
					We may at any time, and in our sole discretion, without prior notice to you, terminate your use of our
					games and products and block your Account if we consider that you are in breach of any of these Terms and
					Conditions or that you are otherwise acting illegally. We will not be under any obligation in such
					circumstances to refund or otherwise reimburse you for any of the funds in your Account.
				</li>
				<li>
					If you don't understand any of these Terms and Conditions and would like to discuss these with us, please
					contact us by:
					<ol type='a'>
						<li>
							telephone on&nbsp;
							<strong>01384 885040</strong>&nbsp;or request a free call-back by texting HELP to 84988; or
						</li>
						<li>live chat, via the Support Button on the Website.</li>
					</ol>
				</li>
				<li>
					For your protection and for training purposes, any telephone calls and live chat transcripts with us are
					recorded and monitored and may be used as evidence in any dispute.
				</li>
			</ol>
			<h4>Section 3: Changes to these Terms and Conditions</h4>
			<ol start={8}>
				<li>
					We reserve the right to withdraw our betting and gaming products, at any time, and will not be liable to
					you as a result of any such action.
				</li>
				<li>
					We may at our sole discretion, make changes to these Terms and Conditions, our Rules and/or our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/privacy-policy/' target='_blank' rel='noreferrer'>
						Privacy Policy
					</a>
					&nbsp;at any time. However before any material changes come into effect, we will provide you with
					reasonable advance notice. If you do not wish to accept these changes, you may close your Account and/or
					stop using our betting and gaming products.
				</li>
			</ol>
			<h4>Section 4: Opening an Account</h4>
			<ol start={10}>
				<li>
					In order to use our betting and gaming products, you will need to create an Account on our Website.&nbsp;
					<strong>You are entitled to open and keep only one Account with us.</strong>
				</li>
				<li>
					You can use our betting and gaming products only if you are of the legal age, as determined by the law of
					the country in which you live.
				</li>
				<li>
					You cannot use our betting and gaming products if you are under 18 years of age, in any circumstances. If
					you are under 18 years of age, you will be guilty of a criminal offence in the UK. We will not open an
					Account unless we believe, acting reasonably, that you are aged 18 years or over. It is illegal in the UK
					for anyone to facilitate gambling for anyone aged less than 18 years of age.
				</li>
				<li>
					We are entitled to verify any information you give us when you open an Account to ensure you are aged 18
					years or over and to verify your identity to ensure that the information you have provided is true,
					complete and accurate. We may supply the information you have given us to authorised credit reference and
					data aggregator agencies to confirm your Name, Address and Date of birth. They will check any details we
					disclose to them against relevant databases (public or private) to which they have access, and will keep
					a record of that check. We may carry out secondary verification checks in any circumstances which give
					rise to suspicion that you may be underage, to verify the accuracy and completeness of the information
					provided and/or to establish source of funds.
				</li>
				<li>
					In addition to third party verification checks, we may carry out manual checks to confirm your address
					and identity. We may ask you to submit a copy of your documents, such as a valid Passport or Driving
					Licence to prove your identity and a proof of your address such as a copy of a recent utility bill.
				</li>
				<li>
					You agree that we may, in accordance with our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/privacy-policy/' target='_blank' rel='noreferrer'>
						Privacy Policy
					</a>
					, process, use, record and disclose personal information which you provide in connection with your
					registration for an Account and that such personal data may be recorded by us or any credit reference or
					data aggregator agency.
				</li>
				<li>
					You will not be able to deposit, play (including the use of bonuses or free to play games) or withdraw
					any winnings from your Account until our identity verification process has been satisfactorily completed.
				</li>
				<li>
					You may not use our betting and gaming products for any commercial use, or on behalf of another person.
					Any use of our betting and gaming products by you must be for your private purposes only.
				</li>
				<li>
					Further information about our safer gambling policies and procedures can be found on our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/safer-gambling/' target='_blank' rel='noreferrer'>
						safer gambling
					</a>
					&nbsp;website page. If you have any comments or queries in respect of this, please do not hesitate to
					contact us.
				</li>
				<li>
					We take our regulatory responsibilities very seriously. As a safer gambling operator, we take all
					necessary steps to ensure that our Players are protected, as well as ensuring that our business operates
					in a responsible and legal manner. Every precaution is made to protect and advise our Players of the
					potential for gambling related harm, as well as ensuring the legitimacy of our Players.
				</li>
				<li>
					No officer, director, employee, consultant or agent of ours or any of our group companies, suppliers,
					vendors or software developers is permitted to use our betting and gaming products directly or
					indirectly, for personal use and enjoyment. This restriction also applies to relatives of such persons
					and for this purpose a "relative" includes, without limitation, any spouse, partner, parent, child,
					step-child or sibling. If we determine in our sole discretion that such use has taken place it will not
					be possible for any winnings to be withdrawn from any such use and it may be appropriated to take
					disciplinary action against those involved.
				</li>
			</ol>
			<h4>Section 5: Deposits</h4>
			<ol start={21}>
				<li>
					<strong>
						Any money deposited by you will go into your Cashable Credit Account. You may withdraw any any funds
						in your cashable credit account at any time.
					</strong>
				</li>
				<li>
					<strong>
						A fixed fee of £3 will be payable for any individual withdrawal (including funds from your Cashable
						Credit Account) under £10.
					</strong>
				</li>
				<li>
					You may make a deposit by debit card and Paysafe Card. In addition, we may offer you a facility to top-up
					your Account by applying a charge to your mobile phone bill or mobile phone pre-pay account. We reserve
					the right to withdraw or limit any payment facility at any time and without notice. You may not use this
					facility if doing so is in breach of the terms of your mobile network operator agreement. If we are
					advised by your mobile network operator of your non-payment or misuse of this facility, or if payment is
					withheld by your operator or we reasonably suspect your non-payment or misuse of this facility, we may
					ask you to provide evidence of payment to your network operator and, if you have not been able to provide
					this for a period of 12 months or more, we reserve the right to close your Account. You will lose any
					amounts deposited and will not be entitled to withdraw any amounts in your Account if your Account is
					closed in these circumstances.
				</li>
				<li>
					We may at any time offset any positive balance in your Cashable Credit Account and refuse/void
					withdrawals against any amounts owed by you to us including, but not limited to, any fraudulent
					chargebacks and any bad debts related to your non-payment of your mobile phone bill.
				</li>
				<li>
					There is no maximum deposit amount unless you are using your mobile phone account, in which case your
					mobile phone carrier may impose daily/monthly limits on how much you are entitled to deposit by phone in
					any 24 hour period.
				</li>
				<li>
					The minimum deposit to play our betting and gaming products is £3 by mobile phone or £5 by debit card,
					PayPal and all other available methods.
				</li>
			</ol>
			<h4>Section 6: Valid Stakes, Bets or Wagers</h4>
			<ol start={27}>
				<li>
					You may stake up to the amount of the balance available in your Account. We do not offer Accounts with a
					credit facility.
				</li>
				<li>Winnings which are generated from promotional free spins will go into your Bonus Credit Account.</li>
				<li>
					When a bet or stake is placed and confirmed, the corresponding amount is deducted from your Account
					balance.
				</li>
				<li>
					Game specific rules will be made available to you when you start playing a game. These rules can be
					located on the Website and within each game. These are referred to as the Rules of the Games. It is your
					responsibility to read and understand these game specific rules before you start playing.
				</li>
				<li>
					It is your responsibility to check that the bet instructions you have submitted are correct before
					committing to play.
				</li>
				<li>Winnings from deposits will go into your Cashable Credit Account.</li>
				<li>
					All bonuses (including winnings from promotional free spins) will be credited to your Bonus Credit
					account and are subject to the Bonus Credit Wagering Requirement. Winnings from Bonus Credit will also be
					credited to your Bonus Credit account.
				</li>
				<li>
					Once your Bonus Credit Wagering Requirement Balance is £0, any remaining Bonus Credit will automatically
					be transferred as winnings to your Cashable Credit account.
				</li>
				<li>
					If your Bonus Credit Account Balance is £0, you should contact us to remove any remaining wagering
					requirements from your account. You can contact our Customer Support team on 01384 885040.
				</li>
				<li>
					In the event that you withdraw Cashable Credit before you have met your Bonus Credit Wagering Requirement
					(i.e. before your Bonus Credit Wagering Requirement Balance is £0) your Bonus Credit and Bonus Credit
					Wagering Requirement will be reduced by the same percentage that you have withdrawn from your Cashable
					Credit. By way of example only: you have made a first deposit of £10, credited to your Cashable Credit
					account, and received a first deposit match bonus of £10, credited to your Bonus Credit account; your
					Bonus Credit Wagering Requirement Balance is £400 (i.e. 40 x bonus of £10); you play through your £10
					deposit once on a slots game and win £50. Your Bonus Credit Wagering Requirement Balance is unchanged (as
					you have not yet staked any Bonus Credit); you are free to withdraw the £50 winnings, stored in your
					Cashable Credit account, but you choose to only withdraw £25 (i.e. 50%); as your Bonus Credit Wagering
					Requirement Balance is not yet £0, you will forfeit 50% of your Bonus Credit (reducing it to £5) and your
					Bonus Credit Wagering Requirement Balance will be reduced by 50% (reducing it to £200).
				</li>
				<li>If bonus abuse is discovered we may at our discretion remove rewards as outlined in section 7b.</li>
				<li>
					In the event that you stake both Cashable Credit and Bonus Credit, any winnings will be applied
					proportionately to your Cashable Credit and Bonus Credit accounts and your Bonus Credit Wagering
					Requirement Balance will be reduced only by the amount of Bonus Credit used. By way of example only: you
					have deposited £5, credited to your Cashable Credit account, and have received a first deposit match
					bonus of £5, credited to your Bonus Credit Account; your Bonus Wagering Requirement is £200 (i.e. 40 x
					bonus of £5); you elect to stake £10 on a slots game; as you do not have enough Cashable Credit to cover
					your stake, the remaining £5 (50%) will be deducted from your Bonus Credit and your Bonus Credit Wagering
					Requirement Balance will be reduced by £5 to £195; you win £50; £25 of your winnings (i.e. 50%) will be
					credited to your Cashable Credit account; and £25.00 of your winnings (i.e. 50%) will be credited to your
					Bonus Credit account.
				</li>
				<li>
					When betting on our betting and gaming products, the percentage of your stake that will contribute to the
					Bonus Credit Wagering Requirement is as follows:-
				</li>
			</ol>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell width='253'>Betting and Gaming product</TableCell>
							<TableCell width='264'>%</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell width='253'>Slots</TableCell>
							<TableCell width='264'>100</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width='253'>Bingo</TableCell>
							<TableCell width='264'>100</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width='253'>Roulette</TableCell>
							<TableCell width='264'>10</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width='253'>Blackjack</TableCell>
							<TableCell width='264'>10</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<ol start={40}>
				<li>
					Stakes from your Account will be made in this order:
					<ol type='a'>
						<li>free spins (if any);</li>
						<li>any amounts in your Cashable Credit Account which comprise of winnings from bonus credit;</li>
						<li>any amounts in your Cashable Credit Account which comprise of winnings from deposits;</li>
						<li>any deposited amounts in your Cashable Credit Account; and</li>
						<li>any bonus amounts in your Bonus Credit Account.</li>
					</ol>
				</li>
				<li>
					By placing a real money bet, you warrant that you are legally able to do so within your jurisdiction and
					that you accept that we are unable to provide any warranties as to the legality or otherwise of your
					participation in real money play. It is your responsibility to determine if remote gambling is legal in
					your jurisdiction.
				</li>
				<li>
					In the event of a disconnection from the service whilst you are using our betting and gaming products,
					you are advised to log back in as quickly as possible. If you experience disconnection difficulties on a
					regular basis, please consult your service provider as to what measures can be initiated to help reduce
					this risk. We shall not be responsible for any damages, liabilities or losses suffered as result of the
					disconnection of Players during play.
				</li>
			</ol>
			<h4>Section 7: Bonuses</h4>
			<h4>Section 7a: General rules applicable to bonuses</h4>
			<ol start={43}>
				<li>
					We offer a number of opportunities for Players to qualify for bonus money which is added to a Player's
					Bonus Credit Account. This includes, for new Players, the Introductory Free Spins, Deposit Activated Free
					Spins and the Deposit Match Bonus. Other bonuses are available from time to time. All bonuses can be used
					to win real money.
				</li>
				<li>
					All bonuses will be subject to successful verification of your identity (Name, Address and Date of
					Birth). No bonuses can be used until the process is complete.
				</li>
				<li>
					Bonuses will be added to your Bonus Credit Account. All bonuses are subject to the Bonus Wagering
					Requirement and specific game stake contribution % as stated in Section 6 Term 39. Winnings arising out
					of Bonus Credit will also be credited to your Bonus Credit Account. Once your Bonus Credit Wagering
					Requirement Balance is £0, any remaining Bonus Credit will automatically be transferred as winnings to
					your Cashable Credit Account.
				</li>
				<li>
					When you download a game with a free bonus attached, the bonus can be claimed only once. There will be no
					bonuses for subsequent downloads of the same game by you.
				</li>
				<li>You will receive regular updates by text message advising you of bonuses received.</li>
				<li>
					From time to time we may offer bonuses that, if not used, expire after a certain time period. Please note
					that if the bonus is not used within this time period, it will disappear from your Account.
				</li>
				<li>
					If you sign up to a promotion offering a free bonus or free spins without the need to make a deposit the
					maximum withdrawal that you can make is £50 on Cashmo if you are a UK Player and £50/ 50 euros/ $50 or
					other currency equivalent if you are a non-UK Player. This withdrawal limit applies to each promotion of
					this type providing you have made at least one deposit on your respective account. If you have not made
					any deposits then this withdrawal limit is applied throughout the lifetime of your respective account for
					funds won from promotions of this type.
				</li>
			</ol>
			<h4>Section 7b: Abuse of Bonuses</h4>
			<ol start={50}>
				<li>
					We reserve the right to close your Account or remove your bonus facility if you are abusing our bonus
					promotions. Examples of abuse include, but are not limited to:
					<ol type='a'>
						<li>
							evidence of a series of bets being placed by a Player, or a group of Players, which, due to a
							deposit bonus or other promotional offer, results in guaranteed wins;
						</li>
						<li>evidence of collusion between Players in connection with our casino games;</li>
						<li>
							abuse of the Refer a Friend Bonus by, for example, creating duplicate accounts or purchasing a
							mobile phone database and using those phone numbers to create accounts; and/or
						</li>
						<li>
							the same Player opening more than one Account with us in order to benefit from New Player or other
							bonuses.
						</li>
						<li>withdrawing a qualifying deposit after bonus has been awarded</li>
					</ol>
				</li>
				<li>
					Subject to clauses 81 and 82 below, if your Account is closed due to abuse of bonus promotions, you may
					lose any amounts deposited and may not be entitled to withdraw any amounts from your Account.
				</li>
			</ol>
			<h4>Section 7c: Introductory Free Spins</h4>
			<ol start={52}>
				<li>
					If you are a new Player, upon registration and passing our verification procedures, you may be awarded up
					to 50 Free Spins via our feature game, to use in a slots game of our choosing. You will receive a text
					message confirming any Free Spin award.
				</li>
				<li>If not used, your Free Spins will expire within 7 days of you having received them.</li>
				<li>
					If, whilst using free spins, additional free spins are won as a prize, the prize free spins will expire
					at the same time as the free spins from which they were won.
				</li>
				<li>
					Winnings from free spins will be added to your Bonus Credit and will be subject to the Bonus Credit
					Wagering Requirement. When you have met your Bonus Credit Wagering Requirement, any remaining Bonus
					Credit will the transferred as winnings to your Cashable Credit account.
				</li>
			</ol>

			<h4>Section 7d: Up to £500 Deposit Match Bonus</h4>

			<ol start={56}>
				<li>
					Provided you make a deposit of at least £10 each time, you may receive Free Spins(locked to a game of our
					choosing) and a Deposit Match Bonus on your first three (3) deposits as follows:
				</li>
			</ol>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell width='64'>Deposit</TableCell>
							<TableCell width='152'>Free Spins available</TableCell>
							<TableCell width='162'>% Deposit Match Bonus available</TableCell>
							<TableCell width='151'>Maximum Deposit Match Bonus Available</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell width='64'>1st</TableCell>
							<TableCell width='152'>Up to 20</TableCell>
							<TableCell width='162'>Up to 200%</TableCell>
							<TableCell width='151'>£100</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width='64'>2nd</TableCell>
							<TableCell width='152'>Up to 30</TableCell>
							<TableCell width='162'>Up to 150%</TableCell>
							<TableCell width='151'>£100</TableCell>
						</TableRow>
						<TableRow>
							<TableCell width='64'>3rd</TableCell>
							<TableCell width='152'>Up to 50</TableCell>
							<TableCell width='162'>Up to 100%</TableCell>
							<TableCell width='151'>£300</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<ol start={57}>
				<li>The maximum free spins available is 100 and the maximum Deposit Match Bonus available is £500.</li>
				<li>
					Deposit Match Bonuses and winnings from free spins will be:
					<ol type='a'>
						<li>automatically credited to your Bonus Credit account within 10 minutes of your award; and</li>
						<li>
							subject to the Bonus Credit Wagering Requirement. When you have met your Bonus Credit Wagering
							Requirement, any remaining Bonus Credit will be transferred as winnings to your Cashable Credit
							account.
						</li>
					</ol>
				</li>
				<li>
					The first qualifying deposit must be made within 30 days of account creation to claim the first deposit
					match. Any further deposit match must be claimed within the 30 days following the previous deposit. If
					any of your first three deposits are below the qualifying amount of £10 then the respective deposit match
					will be skipped and you will be eligible to claim the subsequent deposit match (if applicable).
				</li>
			</ol>
			<h4>Section 7e: Locked Free Spins</h4>
			<ol start={60}>
				<li>
					We may occasionally issue free spin bonuses (e.g. a new game download free spins offer) which are locked
					to a game or a selection of games for you to choose from. The bet amount and number of win lines for
					Locked Free Spins will vary from time to time and will be specified in the promotion.
				</li>
				<li>
					The Locked Free Spins Bonuses may be time limited and, if not used in the time period specified in the
					promotion, will disappear from your Account. If, whilst using Locked Free Spins, additional free spins
					are won as a prize, the prize free spins will expire at the same time as the Locked Free Spins from which
					they were won.
				</li>
				<li>
					Winnings from Locked Free Spins (and prize free spins) will be added to your Bonus Credit Account and
					will be subject to the Bonus Credit Wagering Requirement. Winnings from Bonus Credit will be credited to
					your Bonus Credit Account. When you have met your Bonus Credit Wagering Requirement, any remaining Bonus
					Credit will be transferred as winnings to your Cashable Credit Account.
				</li>
			</ol>
			<h4>Section 7f: Refer a Friend Bonus</h4>
			<ol start={63}>
				<li>
					A Refer a Friend Bonus may be available to you. Players who refer a friend will be eligible to receive a
					bonus up to the value of 50% of their friend’s first deposit, up to a maximum of £100.
				</li>
				<li>
					The bonus will become available once the referred friend has successfully verified their account and has
					deposited and wagered through a minimum of £10. Players will then have 3 days to claim this bonus, the
					exact value of which is to be decided by a feature game, using a Random Number Generator (RNG).
				</li>
				<li>
					The friend must be referred by using the ‘Refer a Friend’ option from the game menu. If they are not
					already registered with us, we will send them a text message with a time limited activation link.&nbsp;
					Your friend must respond to this activation link, register with us within 7 days of receipt and must have
					satisfied our verification processes before the bonuses are applied.
				</li>
				<li>
					Refer a Friend Bonuses will be credited to your Bonus Credit account and will be subject to the Bonus
					Credit Wagering Requirement. When you have met your Bonus Credit Wagering Requirement, any remaining
					Bonus Credit will the transferred as winnings to your Cashable Credit account.
				</li>
			</ol>
			<h4>Section 7g: Promotional / Feature Bonus</h4>
			<ol start={67}>
				<li>
					We may from time to time offer you the chance to access our promotional feature bonuses. The feature
					bonus is time limited and will be available to you only until the timer in your game lobby runs out.
				</li>
				<li>
					Where you are required to pay to play the feature bonus, the cost will be stated on the promotion. Where
					you are required to make a deposit to qualify for the feature bonus, the minimum deposit is £3 by phone
					bill and £5 by all other available methods.
				</li>
				<li>Where a feature prize has a monetary value, that value will be paid in bonus credit.</li>
				<li>
					Where a feature prize comprises bonus spins, the winnings from those bonus spins will be paid in bonus
					credit.
				</li>
				<li>
					All bonus credit will be subject to the Bonus Credit Wagering Requirement. Winnings from Bonus Credit
					will be credited to your Bonus Credit Account. When you have met your Bonus Credit Wagering Requirement,
					any remaining Bonus Credit will be transferred as winnings to your Cashable Credit Account.
				</li>
			</ol>
			<h4>Section 7h: Game Download Bonus</h4>
			<ol start={72}>
				<li>
					We may offer game download bonuses from time to time either in the form of Locked Free Spins or bonus
					credit with a monetary value. The amount of Locked Free Spins or Game Download Bonus Credit will be
					stated in the relevant game download promotion.&nbsp; Game Download Bonuses will be credited to your
					Bonus Credit Account and will be subject to the Bonus Credit Wagering Requirement.
				</li>
				<li>The Game of the Month bonus will expire 24 hours after the Game of the Month has been downloaded.</li>
				<li>
					Winnings from Game Download Bonus Credit will be paid into your Bonus Credit Account. When you have met
					your Bonus Credit Wagering Requirement, any remaining Bonus Credit will be transferred as winnings to
					your Cashable Credit Account.
				</li>
			</ol>
			<h4>Section 8: Progressive Mega Jackpot</h4>
			<ol start={75}>
				<li>All Cashmo Slot Games with the Mega Jackpot Icon will operate a Progressive Mega Jackpot</li>
				<li>
					This Jackpot will be shared between all of the games with the Mega Jackpot Icon. The Mega Jackpot
					Promotional Pot System is available on any stake on any of these games.
				</li>
				<li>
					During any spin the Progressive Mega Jackpot can be triggered by achieving Five Jackpot symbols on an
					active win line on a Five Reel game, or by achieving Three Jackpot symbols on an active win line on a
					Three Reel game
				</li>
				<li>
					The Progressive Mega Jackpot is available on any bet on any game with the Mega Jackpot Icon, with 0.025%
					of any bet being split evenly between the Progressive Mega Jackpot and the Reserve pot.
				</li>
				<li>The Mega Jackpot seed value is £50,000 and will continue rising until it is won</li>
				<li>
					When the Jackpot is won in any of the participating games, it is reset to the value of 50% of reserve
					value or 100% of the seed value, whichever is higher
				</li>
				<li>
					Simultaneous wins can occur, if another player wins the progressive Jackpot then a notification to this
					effect would occur BEFORE the current player was awarded the Jackpot
				</li>
				<li>The likelihood of winning the Mega Jackpot increases in correlation with the player’s bet amount</li>
				<li>
					In the event of a communication error after a Mega Jackpot win result has been determined and before it
					has been displayed on screen, the win amount will automatically be transferred to the player’s account.
					While the game is live, the Jackpot cannot be terminated
				</li>
			</ol>
			<h4>Section 9: Withdrawals</h4>
			<ol start={84}>
				<li>
					You may withdraw any funds from your Cashable Credit account at any time, methods include Bank Transfer,
					Debit Cards and PayPal.
				</li>
				<li>
					You will be charged a fixed fee of £3 for any individual withdrawal from your Cashable Credit Account
					under £10.
				</li>
				<li>
					If you have not made a deposit, there is a minimum withdrawal amount of £30 for UK players and €50 for
					Non-UK players
				</li>
				<li>
					If you sign up to a promotion offering a free bonus or free spins without the need to make a deposit the
					maximum withdrawal that you can make is £50 on Cashmo if you are a UK Player and £50/ 50 euros/ $50 or
					other currency equivalent if you are a non-UK Player. This withdrawal limit applies to each promotion of
					this type providing you have made at least one deposit on your respective account. If you have not made
					any deposits then this withdrawal limit is applied throughout the lifetime of your respective account for
					funds won from promotions of this type.
				</li>
				<li>
					<strong>
						We reserve the right to void bets and refuse or withhold a withdrawal and offset any positive balance
						in your Cashable Credit Account where we have any reasonable grounds for closing your Account (see
						Section 10 below), including where there is evidence of "suspicious activity" (see Section 13 below).
						In particular, if we suspect fraud or fraudulent activity on your part, or any of your deposits are
						charged back to us as a result of (alleged) fraudulent use, or loss, of your credit or debit card, we
						reserve the right to refuse or withhold a withdrawal from your Account and, if necessary, to lawfully
						collect any payments owed by you.
					</strong>
					It is your responsibility to provide correct bank details for winning payments. We will make reasonable
					efforts to contact you to obtain correct bank details.
				</li>
				<li>
					We may ask you to provide other information, such as proof of payment of a phone bill, before we make a
					withdrawal payment.
				</li>
				<li>
					<strong>
						We aim to process withdrawals for depositing customers within one working day (being Monday to Friday,
						excluding Bank Holidays) of a request. Withdrawals for non-depositing customers will take 3 working
						days. Where we require source of funds, proof of payment of phone bill or any other documentation,
						withdrawals will be delayed pending verification of such documents
					</strong>
				</li>
			</ol>
			<h4>Section 10: Closing and Suspending Accounts</h4>
			<p>
				<strong>
					<u>Our Rights to Close and Suspend Your Account</u>
				</strong>
			</p>
			<ol start={91}>
				<li>
					We reserve the right to close or suspend an Account in any of the following circumstances:
					<ol type='a'>
						<li>
							<strong>If the Player has more than one active Account;</strong>
						</li>
						<li>
							<strong>
								If the mobile phone account registered on an Account is not active, is suspended or is disabled
								by the relevant mobile network;
							</strong>
						</li>
						<li>
							<strong>
								If the name registered to a Account does not match the name on the Payment card(s) used to make
								deposits into the Account;
							</strong>
						</li>
						<li>
							<strong>
								If the name on the Account does not match the name on the phone bill or any other depositing
								method used to make deposits into the Account;
							</strong>
						</li>
						<li>
							<strong>
								If the Player deliberately provides incorrect or misleading registration information;
							</strong>
						</li>
						<li>
							<strong>If the Players identity (Name, Address and Date of Birth) cannot be verified.</strong>
						</li>
						<li>
							<strong>
								If the Player is not of legal age in their jurisdiction or in any event is under 18;
							</strong>
						</li>
						<li>
							<strong>
								If the Player resides in a jurisdiction where placing bets on our betting and gaming products is
								prohibited by law;
							</strong>
						</li>
						<li>
							<strong>
								If the Player has allowed or permitted (intentionally or unintentionally) someone else to play
								on his/her Account;
							</strong>
						</li>
						<li>
							<strong>
								If the Player is found to have cheated or if it is determined by us that he/she has employed or
								made use of a system (including machines, computers, software or other automated systems)
								designed specifically to manipulated the Software or game to their benefit;
							</strong>
						</li>
						<li>
							<strong>
								Where we have evidence of suspected abuse of a bonus promotion (in accordance with clause 49
								above);
							</strong>
						</li>
						<li>
							<strong>
								Where we have requested additional information relating to identification, proof of payment of a
								phone bill or proof of source of funds/wealth and the Player has failed to provide such
								information or has failed to participate and/or provide information in a telephone or video call
								as requested. In these circumstances the account will be suspended within 90 days of the initial
								request;
							</strong>
						</li>
						<li>
							<strong>If the Player is a Politically Exposed Person;</strong>
						</li>
						<li>
							<strong>Where we are legally obliged to do so; and/or</strong>
						</li>
						<li>
							<strong>Where we reasonably suspect "suspicious activity" (see Section 13 below).</strong>
						</li>
						<li>
							<strong>
								In Touch Games reserves the absolute right and without limitation to close your account on
								reasonable notice to you
							</strong>
						</li>
					</ol>
				</li>
				<li>
					<strong>
						In most cases, Account closure will result in the balance being removed from both your Bonus Credit
						Account and your Cashable Credit Account 6 months following the date of Account closure. During this 6
						month period, you may be entitled to reclaim credit in your Cashable Credit Account by contacting our
						Customer Support Team on 01384 885040 and/or making a Complaint (see Section 18).
					</strong>
				</li>
				<li>
					<strong>
						In circumstances where you have not accessed your Account for a period of 12 months, your Account will
						be suspended. If there is credit in your Cashable Credit Account, we will use reasonable endeavours to
						contact you to notify you of the steps you would need to take to withdraw any credit from your
						Cashable Account Credit and of any charges may be applied to your account if your account remains
						inactive (see Section 10b: Dormant Accounts for more detail ).
					</strong>
				</li>
				<li>
					<strong>
						If you have failed to verify your identity, age and/or payment of your phone bill and a period of 12
						months has lapsed since our last attempt to contact you, or your last attempt to provide us with the
						relevant information and/or documentation and there is credit in your Cashable Credit Account, charges
						may be applied to your account if you fail to provide the required information/and or documents and
						your account remains dormant (see Section 10b: Dormant Accounts for more details).
					</strong>
				</li>
				<li>
					<strong>
						You have the right to close your Account at any time. You should indicate your desire to close your
						Account by following the instructions in the&nbsp;
						<a href='https://www.cashmo.co.uk/support/' target='_blank' rel='noreferrer'>
							Support section
						</a>
						&nbsp;of the website. Please see Section 12 for closures for self-exclusion reasons.
					</strong>
				</li>
			</ol>
			<h4>Section 10b: Dormant Accounts</h4>
			<ol start={96}>
				<li>
					We will apply an Administration Fee to all accounts that have been ‘inactive’ for a consecutive period of
					at least 12 months, in accordance with the following procedure. When this time has elapsed, an account
					will be considered “dormant”.
				</li>
				<li>
					An account is deemed to be ‘inactive’ during any period in which none of the following actions take place
					in relation to it:
					<ol type='a'>
						<li>a successful deposit;</li>
						<li>a valid wager</li>
						<li>participation in any game, poker or bingo product</li>
					</ol>
				</li>
				<li>
					f a dormant account has an account balance of zero, this account will be closed and no fees shall apply
				</li>
				<li>
					When an account is considered dormant, and has a positive balance, we will take reasonable steps to
					notify you, using the details provided on your account
				</li>
				<li>
					The Administration Fee will begin to be deducted from the account after a minimum of 30 days from the
					date that we tried to contact you.
				</li>
				<li>
					The Administration Fee will be £3 (or currency equivalent) or 5% of the balance, whichever is higher.
				</li>
				<li>The Administration Fee will show in your account transactions as “Account Administration Fee”</li>
				<li>
					The Administration fee will continue to be deducted on a monthly basis until:
					<ol type='a'>
						<li>The account balance reaches zero, or</li>
						<li>The account has been "reactivated”</li>
					</ol>
				</li>
				<li>
					An account can be “reactivated” by:
					<ol type='a'>
						<li>A Successful transaction (withdrawal or deposit)</li>
						<li>A valid wager</li>
					</ol>
				</li>
				<li>
					You can request a refund of any Administration Fees by contacting our Customer Service department by
					telephone on 01384 885040 or request a free call-back by texting HELP to 84988. We will consider requests
					on a case-by-case basis.
				</li>
			</ol>
			<h4>Section 11: Security</h4>
			<ol start={106}>
				<li>
					All Accounts are linked to a mobile phone number. We adopt the same stance as all network providers in
					relation to the security of mobile devices. It is your responsibility to ensure that the device is
					password protected to prevent its unauthorised use. We will not be liable for any loss you suffer as a
					result of your mobile device not being properly protected.
				</li>
				<li>
					We allow access to our betting and gaming products only through secured networks using encryption of the
					Player’s username and password. You cannot play our betting and gaming products without passing our
					Player security login.
				</li>
				<li>
					You must keep your username and password confidential and should not disclose them to anybody. You may
					not use anyone else’s username and password. You shall be responsible for all transactions conducted in
					relation to your Account using your password. Every person who identifies him/herself by entering your
					correct username and password is assumed by us to be you and all transactions where the username and
					password have been entered correctly will be regarded as valid. To the extent permitted by law, we will
					not be liable for any loss you suffer as a result of unauthorised use or misuse of your login details.
				</li>
				<li>
					We reserve the right to ask you to provide us with additional documents as part of our Enhanced Due
					Diligence process such as proof of the payment method used and source of wealth. We may also request
					further proof of identification relating to the details you have used to register your Account. Any such
					additional identification will be kept confidential. We may request that you take part in a video call
					with us as part of this process. Where we deem it necessary to conduct a video call to conduct further
					checks, your account access may be temporarily restricted and any withdrawals will be put on hold pending
					receipt and verification of any documents requested by us. We further reserve the right to disclose your
					details, under certain circumstances such as those required by law. Further details on our identity
					verification procedure can be found in Section 4.
				</li>
				<li>
					We are required by our Gambling Commission licence to inform you about what happens to funds we hold on
					account for you, and the extent to which these funds are protected in the event of our insolvency (
					<a
						href='https://www.gamblingcommission.gov.uk/for-the-public/Your-rights/Protection-of-customer-funds.aspx'
						target='_blank'
						rel='noreferrer'
					>
						for further information please see the Gambling Commission website
					</a>
					). We have taken steps to protect you by maintaining all Player funds in a separate Natwest bank account.
					The balance of this account always exceeds the total of our current customer balances. Whilst these funds
					are completely separate from our funds, and Natwest Bank is not entitled to combine this account with any
					other account held in our name or to exercise any right of set-off, they are not necessarily protected in
					the event of insolvency. This meets the Gambling Commission’s requirements for the segregation of Player
					funds at the ‘not protected’ segregation level.
				</li>
				<li>
					You shall not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to
					your or third parties’ participation in any of our betting and gaming products and shall not use any
					software-assisted methods or techniques or hardware devices for your participation in the same. We hereby
					reserve the right to invalidate any betting in the event of such behaviour and close your account.
				</li>
			</ol>
			<h4>Section 12: Safer Gambling</h4>
			<ol start={112}>
				<li>
					In the interests of promoting safer gambling, we provide a voluntary self-exclusion option. At your
					request to self-exclude, we will lock your Account, which will prevent you from having access for at
					least 6 months. You may opt to extend the self-exclusion for a period of 5 years or more.
				</li>
				<li>
					Once the self-exclusion is in place, you will not be able to access your Account, place bets or make
					deposits and all marketing material will cease. We will return any available winnings and the balance of
					any deposits from the Cashable credit account to you. Furthermore, when you self-exclude with us you
					agree to be automatically self-excluded from all In Touch Games Limited brands (mFortune, PocketWin, Mr
					Spin, Dr Slot and Cashmo). Any accounts which are locked due to the self-exclusion cannot be unlocked
					during the agreed self-exclusion period. When the self-exclusion period has elapsed, the self-exclusion
					will remain in place for a minimum of 7 years, unless you take positive action to gamble again.
				</li>
				<li>
					In requesting to be self-excluded, you agree to provide full and accurate personal details, now and in
					the future, so your access/use of our betting and gaming products can be restricted. If you do choose to
					self-exclude, we will use all reasonable endeavours to ensure that we comply with your self-exclusion.
					However in agreeing to self-exclude, you accept that you have a parallel responsibility or liability for
					any subsequent losses, howsoever caused, that you may suffer or incur by seeking to circumvent the
					self-exclusion agreed.
				</li>
				<li>
					You may reactivate your Account at the end of the self-exclusion period by contacting the&nbsp;
					<a href='https://www.cashmo.co.uk/support/' target='_blank' rel='noreferrer'>
						Customer Support Team
					</a>
					&nbsp;on&nbsp;
					<strong>01384 885040</strong>. Re-registering an Account is not sufficient. Upon any request to unlock an
					Account after the self-exclusion period has passed, a 24 (Twenty Four) hour cooling off period will be
					applied before you will be able to log into your account. See our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/safer-gambling/' target='_blank' rel='noreferrer'>
						Safer Gambling
					</a>
					&nbsp;section for more details.
				</li>
				<li>
					As part of this process, we also recommend that you self-exclude from all other operators at your
					earliest opportunity and remove any gambling applications from your mobile device/tablet. See our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/safer-gambling/' target='_blank' rel='noreferrer'>
						Safer Gambling
					</a>
					&nbsp;section for contact details of help and advice.
				</li>
				<li>
					When setting up your Account, or at any time thereafter, you may also choose to set a limit on the amount
					you may deposit or stake within a specified period of time or on the amount of time you wish to play in
					one session. For more information please refer to the our&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/safer-gambling/' target='_blank' rel='noreferrer'>
						Safer Gambling
					</a>
					&nbsp;section of our Website or contact our&nbsp;
					<a href='https://www.cashmo.co.uk/support/' target='_blank' rel='noreferrer'>
						Customer Support Team
					</a>
					&nbsp;by texting the word HELP to 84988 or call&nbsp;
					<strong>01384 885040</strong>.
				</li>
			</ol>
			<h4>Section 13: Suspicious Activity</h4>
			<ol start={118}>
				<li>
					We are required by law to address suspected money laundering and fraudulent transactions or activity that
					may take place through our business. In order for us to prevent this illegal activity, we have a number
					of policies and procedures in place that bring ‘suspicious activity’ to our attention so that we may deal
					with this in a legally compliant manner.
				</li>
				<li>
					Strict money laundering rules and regulations require us to report suspicious activity to the relevant
					authorities if we know, suspect or have reason to suspect that any of your transactions, among other
					actions:
					<ol type='a'>
						<li>involve funds derived from illegal activities;</li>
						<li>are intended to conceal funds from illegal activities; or</li>
						<li>involve the use of our betting and gaming products to facilitate criminal activity.</li>
					</ol>
				</li>
				<li>
					For the purpose of this Section 13, "suspicious activity" shall refer to where we have reasonable grounds
					to believe that you have placed a bet or a number of bets in suspicious circumstances or in contravention
					of these Terms and Conditions. Suspicious circumstances include but are not limited to:
					<ol type='a'>
						<li>
							where there is a disproportionate frequency and/or highly unusual pattern of play (by comparison
							with normal betting patterns) placed over a short period of time;
						</li>
						<li>
							where we have reason to believe that you are acting with others to abuse the Software, games or
							promotions, or otherwise acting in contravention of these Terms and Conditions;
						</li>
						<li>
							where we have reason to believe that you are misusing the mobile billing facility, including
							without limitation where you deposit funds via phone bill and subsequently do not pay your phone
							bill;
						</li>
						<li>
							where the credit or debit card or other payment method or bank account linked to your Account is in
							a name other than your own; or
						</li>
						<li>
							where there is a disproportionate frequency and/or unusual pattern of deposits in your Account.
						</li>
					</ol>
				</li>
				<li>
					In the case of any suspicious activity, we may, at our sole discretion, depending on the situation:
					<ol type='a'>
						<li>
							request reasonable further information from you to investigate whether the bet(s) constitute(s)
							suspicious betting;
						</li>
						<li>
							suspend your Account and withhold payment of any withdrawals until we have receipt of satisfactory
							evidence (in our reasonable opinion) from you for us to determine that the deposits are in your
							name, that play is not fraudulent or any other purpose connected to our concerns of “suspicious
							activity”. For example, but without limitation, where we suspect that you have deposited funds via
							a mobile phone account which is not in your name or you are in default of settlement of your mobile
							phone bill, we may ask you to provide reasonable evidence that the mobile phone account is yours
							and that you have settled the mobile phone bill that you used to deposit funds before you can
							withdraw any winnings;
						</li>
						<li>
							collect and process any information in connection with your patterns of play, personal data,
							depositing of funds and any other related information and conduct inquiries that will help us to
							investigate any suspected breach of these Terms and Conditions and (where appropriate) share that
							information with relevant external agencies e.g. the National Crime Agency; and/or
						</li>
						<li>
							void or refuse all or any part of any bet, suspend your Account or withhold payment of any amounts
							(or any parts thereof), for a period of time that may reasonably be required (for example, pending
							any investigations by our Internal Security Team, a gambling regulator, a law enforcement authority
							or any other third party with jurisdiction).
						</li>
					</ol>
				</li>
				<li>
					We reserve the right to seek to recover from you any losses we suffer that are directly or indirectly
					connected to any of the activities contemplated in this Section 13. This right is without prejudice to
					any other rights (including common law rights) that we may have against you, whether under these Terms
					and Conditions or otherwise.
				</li>
				<li>
					We will not be liable under any circumstances whatsoever for any loss you or any other person may incur
					as a result of collusive or other behaviour in breach of this Section 13.
				</li>
			</ol>
			<h4>Section 14: Network Operator and Other Charges</h4>
			<ol start={124}>
				<li>
					When you play our games, download the Software, place bets, communicate with us or otherwise use our
					Website or applications, you are using data. Please check that your data usage plan carefully, as using
					too much data might mean that you exceed your data limit and you could face paying more than you were
					expecting, particularly if you are using your mobile phone abroad.
				</li>
				<li>
					You are solely responsible for any connection, or other charges levied by your network operator when you
					play our games, download the Software, place bets, communicate with us or otherwise use our betting and
					gaming products.
				</li>
			</ol>
			<h4>Section 15: Random Number Generator (RNG)</h4>
			<ol start={126}>
				<li>
					The Random Number Generator (RNG) used by us has been audited and verified by an independent laboratory
					approved by the Gambling Commission.
				</li>
				<li>
					You acknowledge that our RNG will determine the outcome of our betting and gaming products and you accept
					the outcomes of all such games. You further agree that in the unlikely event of a disagreement between
					the result that appears on the Software and the game server, the result that appears on the game server
					will prevail and you acknowledge and agree that our records will be the final authority in determining
					the terms and circumstances of your participation in the gaming activity
				</li>
				<li>
					We reserve the right to void any withdrawals and clear any credit in your Cashable Credit and/or Bonus
					Credit Account resulting from any obvious error or mistake or any technical fault (including, but not
					limited to, incorrect game pay-outs) with any of our betting and gaming products.
				</li>
			</ol>
			<h4>Section 16: Non-Cash Prizes and Gifts</h4>
			<ol start={129}>
				<li>From time to time, we offer the opportunity for our Players to win non-cash prizes and gifts.</li>
				<li>
					All prizes must be claimed within 28 days of winning and it is the responsibility of the winner to claim
					their prize.
				</li>
				<li>We reserve the right to dispose of an unclaimed prize at the end of the 28 day period.</li>
				<li>
					If the specified prize is unavailable due to unforeseen circumstances, we may substitute another prize of
					similar or equal value.
				</li>
				<li>
					We hold no responsibility for the management or set up of any insurance cover or warranty of any non-cash
					prizes or gifts awarded.
				</li>
				<li>
					Delivery of a prize or gift is your responsibility. This includes being present at the agreed delivery
					address when the parcel is delivered, providing a signature if required, and if necessary, collecting
					from a courier’s office or sorting office if delivery to the specified delivery address is unsuccessful.
				</li>
				<li>We hold no responsibility for any injury caused by or using our non-cash prizes of gifts.</li>
			</ol>
			<h4>Section 17: Licence to Use Software</h4>
			<ol start={136}>
				<li>
					We hereby grant to you a personal, non-exclusive, non-transferable right to use the Software, to allow
					you to use our betting and gaming products, in accordance with the following provisions of this Section
					17.
				</li>
				<li>
					You are not permitted to: a) sub-license, assign, rent, lease, loan, transfer or copy (except as
					expressly provided elsewhere in these Terms and Conditions), your licence to use the Software or make or
					distribute copies of the Software; b) translate, reverse engineer, decompile, disassemble, modify, create
					derivative works based on, or otherwise modify the Software; c) enter, access or attempt to enter or
					access or otherwise bypass our security system or interfere in any way (including but not limited to,
					with robots and similar devices) with our betting and gaming products or attempt to make any changes to
					the Software and/or any features or components thereof.
				</li>
				<li>
					You do not own the Software. We own the Software and it is our exclusive property. Your use of the
					Software does not give you ownership of any intellectual property rights in the Software.
				</li>
				<li>
					When we supply the Software to you:
					<ol type='a'>
						<li>
							we will use reasonable efforts to ensure that it is free from defects, viruses and other malicious
							content;
						</li>
						<li>
							we do not promise that it is compatible with any third party software or equipment except where we
							have explicitly said so on our Website; and
						</li>
						<li>you acknowledge that there may be minor errors or bugs in it.</li>
					</ol>
				</li>
			</ol>
			<h4>Section 18: Complaints</h4>
			<ol start={140}>
				<li>A complaint may relate to any issue or concern you have about our products or services.</li>
				<li>
					If you have a query or wish to raise a dispute or complaint regarding our products or services, you
					should contact our&nbsp;
					<a href='https://www.cashmo.co.uk/support/' target='_blank' rel='noreferrer'>
						Customer Support Team
					</a>
					&nbsp;on&nbsp;
					<strong>01384 885040</strong>
					&nbsp;or via the "Support" section of our Website.
				</li>
				<li>
					If, having spoken to a member of the Customer Support Team, your query or complaint is not resolved, you
					may request that this is escalated to a manager or supervisor. They will look into your issue and will
					aim to call you back within 24 hours with a resolution. If you are still not satisfied, you may enter our
					formal&nbsp;
					<a href='https://www.cashmo.co.uk/our-terms/complaints-procedure/' target='_blank' rel='noreferrer'>
						Complaints Procedure
					</a>
					&nbsp;(see below) and your complaint will be dealt with as quickly as possible.
				</li>
			</ol>
			<h4>Our Complaints Procedure</h4>
			<ol start={143}>
				<li>
					Please address all correspondence in writing by email to complaints@cashmo.co.uk, by fax to&nbsp;
					<strong>+44 (0)121 5504910</strong>, or by post to Complaints Team, In Touch Games Ltd, Fountain House,
					Great Cornbow, Halesowen, West Midlands, B63 3BL, England.
				</li>
				<li>All correspondence must include your full name and mobile phone number.</li>
				<li>
					We request you make any complaint as soon as possible (within 6 Months of the event occurring to which
					the complaint relates). This will allow us the best opportunity to resolve your complaint.
				</li>
				<li>
					In relation to all complaints or disputes, we record all telephone and email communications. In the event
					of a dispute as to the contents of any such communication, reference will be made to such records and to
					our transaction database which, in the absence of any contrary evidence, shall be decisive.
				</li>
				<li>
					We request that you keep the details of any complaint or dispute you have with us confidential while we
					seek a resolution.
				</li>
				<li>
					We will deal with your issue as quickly as we reasonably can. Where necessary, we may request appropriate
					evidence from you for the purposes of settling your issue.
				</li>
				<li>
					If your issue is not resolved to your satisfaction by the use of our Complaints Procedure, you may refer
					the matter to an independent adjudicator. In Touch Games Ltd is registered with the&nbsp;
					<a className='external_link' href='https://ibas-uk.com/' target='_blank' rel='noreferrer'>
						Independent Betting Adjudication Service (IBAS)
					</a>
					&nbsp;whose contact details are 020 7347 5883 or&nbsp;
					<a
						className='external_link'
						href='https://ibas-uk.com/contact-ibas/'
						target='_blank'
						rel='noopener noreferrer'
					>
						https://ibas-uk.com/contact-ibas/
					</a>
					. Note that IBAS will not consider complaints until our internal procedure has been completed
				</li>
				<li>
					In addition, there is a secondary process that you may wish to follow if you are not satisfied with a
					resolution achieved by following our complaints procedure. This is called the&nbsp;
					<a
						className='external_link'
						href='https://ec.europa.eu/consumers/odr/main/?event=main.home.show'
						target='_blank'
						rel='noopener noreferrer'
					>
						Online Dispute Resolution
					</a>
					&nbsp;(ODR) Platform. The ODR Platform is a European initiative which aims to aid the resolution of
					disputes between consumers and businesses. The Platform will allow consumers to file complaints and will
					then work with both parties to facilitate a resolution via an alternative dispute resolution service.
				</li>
			</ol>
			<h4>Section 19: General Provisions</h4>
			<ol start={151}>
				<li>
					In relation to any prize and/or winnings, we may contact you to request your consent to use your name and
					image for advertising and promotional purposes. If you decide not to grant your consent, this shall not
					in any way affect your right to claim your prize and/or winnings.
				</li>
				<li>
					These Terms and Conditions set out your legal rights and responsibilities, our legal rights and
					responsibilities and certain key information required by law. Nothing in this contract affects your legal
					rights under the Consumer Rights Act 2015 (also known as ‘statutory rights’). You may also have other
					rights in law.
				</li>
				<li>
					We reserve the right to assign, or pledge these Terms and Conditions, in whole or in part, to any person,
					company or legal entity, provided we give you reasonable notice. Any such assignment or pledge will not
					in any way prejudice your rights under these Terms and Conditions or reduce or alter the obligations we
					have under these Terms and Conditions.
				</li>
				<li>
					Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury),
					we are not legally responsible for losses that:
					<ol type='a'>
						<li>were not foreseeable to you and us; or</li>
						<li>that were not caused by any breach on our part</li>
						<li>business losses; and</li>
						<li>losses to non-consumers.</li>
					</ol>
				</li>
				<li>
					If you want to take court proceedings, the courts of England &amp; Wales will have non-exclusive
					jurisdiction in relation to these Terms and Conditions.
				</li>
				<li>These Terms and Conditions are subject to the laws of England and Wales.</li>
				<li>
					The English language version of these Terms and Conditions will prevail over any other language version
					issued by us.
				</li>
				<li>
					The illegality, invalidity or enforceability of any part of these Terms and Conditions will not affect
					the legality, validity or enforceability of the remainder.
				</li>
				<li>
					No one other than a party to these Terms and Conditions has any right to enforce any term of these Terms
					and Conditions.
				</li>
			</ol>
		</div>
	);
};

export default TermsContent;

import { useState } from 'react';
import {
	Button,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText
} from '@mui/material';
import TermsContent from './TermsContent';

import '../../../../variables.scss';

const Content = () => {
	const [open, setOpen] = useState(false);

	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>

			<div className='terms-container'>
				<h3>Section 6: Valid Stakes, Bets or Wagers</h3>
				<p>
					You may stake up to the amount of the balance available in your Account. We do not offer Accounts with a
					credit facility.
				</p>
				<p>Winnings which are generated from promotional free spins will go into your Bonus Credit Account.</p>
				<p>
					When a bet or stake is placed and confirmed, the corresponding amount is deducted from your Account
					balance.
				</p>
				<p>
					Game specific rules will be made available to you when you start playing a game. These rules can be
					located on the Website and within each game. These are referred to as the Rules of the Games. It is your
					responsibility to read and understand these game specific rules before you start playing.
				</p>
				<p>
					It is your responsibility to check that the bet instructions you have submitted are correct before
					committing to play.
				</p>
				<p>Winnings from deposits will go into your Cashable Credit Account.</p>
				<p>
					All bonuses (including winnings from promotional free spins) will be credited to your Bonus Credit
					account and are subject to the Bonus Credit Wagering Requirement. Winnings from Bonus Credit will also be
					credited to your Bonus Credit account.
				</p>
				<p>
					Once your Bonus Credit Wagering Requirement Balance is £0, any remaining Bonus Credit will automatically
					be transferred as winnings to your Cashable Credit account.
				</p>
				<p>
					If your Bonus Credit Account Balance is £0, you should contact us to remove any remaining wagering
					requirements from your account. You can contact our Customer Support Team on 01384 885046.
				</p>
				<p>
					In the event that you withdraw Cashable Credit before you have met your Bonus Credit Wagering Requirement
					(i.e. before your Bonus Credit Wagering Requirement Balance is £0) your Bonus Credit and Bonus Credit
					Wagering Requirement will be reduced by the same percentage that you have withdrawn from your Cashable
					Credit. By way of example only: you have made a first deposit of £10, credited to your Cashable Credit
					account, and received a first deposit match bonus of £10, credited to your Bonus Credit account; your
					Bonus Credit Wagering Requirement Balance is £400 (i.e. 40 x bonus of £10); you play through your £10
					deposit once on a slots game and win £50. Your Bonus Credit Wagering Requirement Balance is unchanged (as
					you have not yet staked any Bonus Credit); you are free to withdraw the £50 winnings, stored in your
					Cashable Credit account, but you choose to only withdraw £25 (i.e. 50%); as your Bonus Credit Wagering
					Requirement Balance is not yet £0, you will forfeit 50% of your Bonus Credit (reducing it to £5) and your
					Bonus Credit Wagering Requirement Balance will be reduced by 50% (reducing it to £200).
				</p>
				<p>If bonus abuse is discovered we may at our discretion remove rewards as outlined in section 7b.</p>
				<p>
					In the event that you stake both Cashable Credit and Bonus Credit, any winnings will be applied
					proportionately to your Cashable Credit and Bonus Credit accounts and your Bonus Credit Wagering
					Requirement Balance will be reduced only by the amount of Bonus Credit used. By way of example only: you
					have deposited £5, credited to your Cashable Credit account, and have received a first deposit match
					bonus of £5, credited to your Bonus Credit Account; your Bonus Wagering Requirement is £200 (i.e. 40 x
					bonus of £5); you elect to stake £10 on a slots game; as you do not have enough Cashable Credit to cover
					your stake, the remaining £5 (50%) will be deducted from your Bonus Credit and your Bonus Credit Wagering
					Requirement Balance will be reduced by £5 to £195; you win £50; £25 of your winnings (i.e. 50%) will be
					credited to your Cashable Credit account; and £25.00 of your winnings (i.e. 50%) will be credited to your
					Bonus Credit account.
				</p>
				<p>
					When betting on our betting and gaming products, the percentage of your stake that will contribute to the
					Bonus Credit Wagering Requirement is as follows:
				</p>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Betting and Gaming Product</TableCell>
								<TableCell>%</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Slots</TableCell>
								<TableCell>100</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Bingo</TableCell>
								<TableCell>100</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Roulette</TableCell>
								<TableCell>10</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Blackjack</TableCell>
								<TableCell>10</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<p>Stakes from your Account will be made in this order:</p>
				<ul>
					<li>free spins (if any);</li>
					<li>any amounts in your Cashable Credit Account which comprise of winnings from bonus credit;</li>
					<li>any amounts in your Cashable Credit Account which comprise of winnings from deposits;</li>
					<li>any deposited amounts in your Cashable Credit Account; and</li>
					<li>any bonus amounts in your Bonus Credit Account.</li>
				</ul>

				<p>
					By placing a real money bet, you warrant that you are legally able to do so within your jurisdiction and
					that you accept that we are unable to provide any warranties as to the legality or otherwise of your
					participation in real money play. It is your responsibility to determine if remote gambling is legal in
					your jurisdiction.
				</p>
				<p>
					In the event of a disconnection from the service whilst you are using our betting and gaming products,
					you are advised to log back in as quickly as possible. If you experience disconnection difficulties on a
					regular basis, please consult your service provider as to what measures can be initiated to help reduce
					this risk. We shall not be responsible for any damages, liabilities or losses suffered as result of the
					disconnection of Players during play.
				</p>

				<h3>Section 7: Bonuses</h3>
				<h4>Section 7a: General rules applicable to bonuses</h4>
				<p>
					We offer a number of opportunities for Players to qualify for bonus credit which is added to a Player’s
					Bonus Credit Account. This includes, for new Players, the Introductory Free Spins, Deposit Activated Free
					Spins and the Deposit Match Bonuses. Other bonuses are available from time to time. All bonuses can be
					used to win real money.
				</p>
				<p>
					All bonuses will be subject to successful verification of your identity (Name, Address and Date of
					Birth). No bonuses can be used until the process is complete.
				</p>
				<p>
					Bonuses will be added to your Bonus Credit Account. All bonuses are subject to the Bonus Wagering
					Requirement and specific game stake contribution % as stated in Section 6 Term 39. Winnings arising out
					of Bonus Credit will also be credited to your Bonus Credit Account. Once your Bonus Credit Wagering
					Requirement Balance is £0, any remaining Bonus Credit will automatically be transferred as winnings to
					your Cashable Credit Account.
				</p>
				<p>
					When you download a game with a free bonus attached, the bonus can be claimed only once. There will be no
					bonuses for subsequent downloads of the same game by you.
				</p>
				<p>
					You may receive regular updates by text message advising you of bonuses received depending on your
					account settings.
				</p>
				<p>
					From time to time we may offer bonuses that, if not used, expire after a certain time period. Please note
					that if the bonus is not used within this time period, it will disappear from your Account.
				</p>
				<p>
					If you sign up to a promotion offering a free bonus or free spins without the need to make a deposit the
					maximum withdrawal that you can make is £50 on Cashmo if you are a UK Player and £50/ 50 euros/ $50 or
					other currency equivalent if you are a non-UK Player. This withdrawal limit applies to each promotion of
					this type providing you have made at least one deposit on your respective account. If you have not made
					any deposits then this withdrawal limit is applied throughout the lifetime of your respective account for
					funds won from promotions of this type.
				</p>
				<Button onClick={() => setOpen(true)}>Full T&amp;Cs for Casino Bonuses</Button>
				<Dialog open={open} onClose={() => setOpen(false)} scroll={'paper'} maxWidth={'xl'}>
					<DialogTitle>Full T&amp;Cs for Casino Bonuses</DialogTitle>
					<DialogContent dividers={true}>
						<DialogContentText>
							<TermsContent />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Close</Button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default Content;

import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content' style={{ color: '#6b6b6b' }}>
			<div className='panel'>
				<p>
					<strong>
						BEFORE CLAIMING THIS PROMOTION IT IS IMPORTANT THAT YOU READ THESE PROMOTIONAL TERMS AND CONDITIONS
						CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION ABOUT THE TERMS ON WHICH THIS PROMOTION CAN BE
						CLAIMED. YOU WILL BE REQUIRED TO AGREE TO THESE PROMOTIONAL TERMS &amp; CONDITIONS WHEN CLAIMING THIS
						PROMOTION. PLEASE FAMILIARISE YOURSELF WITH THE RESTRICTIONS, WITH PARTICULAR ATTENTION TO THE
						WAGERING REQUIREMENTS, SUMMARISED BELOW.
					</strong>
				</p>
				<p>
					These Terms &amp; Conditions apply to this “Spend £10 Get £10 + 100 Free Spins” promotion (“the{' '}
					<strong>Promotion</strong>”). They should be read alongside our General Website Terms &amp; Conditions
					and Promotional Terms &amp; Conditions. These terms can be accessed&nbsp;
					<a
						rel='noopener noreferrer'
						href='https://help.foxygames.com/en/legal-matters/general-terms-and-conditions/general'
						target='_blank'
					>
						HERE
					</a>{' '}
					&amp;{' '}
					<a
						rel='noopener noreferrer'
						href='https://help.foxygames.com/en/general-information/legal-matters/general-terms-and-conditions/standard-promotional-terms-and-conditions'
						target='_blank'
					>
						HERE
					</a>
					.
				</p>
				<p>
					<strong>PROMOTIONAL PERIOD</strong>
				</p>
				<ol>
					<li>This Bonus is available to new customers only from 06.01.22 until further notice.</li>
				</ol>
				<p>
					<strong>WHO QUALIFIES FOR THIS PROMOTION</strong>
				</p>
				<ol start='2'>
					<li>
						This Promotion is available to new customers of{' '}
						<a rel='noopener noreferrer' href='http://www.foxygames.com' target='_blank'>
							foxygames.com
						</a>{' '}
						(the “<strong>Website</strong>”) who:
						<ul>
							<li>are over 18 years old;</li>
							<li>have not made a deposit before;</li>
							<li>have a verified Website account; and</li>
							<li>
								have an account registered in a country where the Promoter accepts customers, which can be seen
								in the list{' '}
								<a
									rel='noopener noreferrer'
									href='https://help.foxygames.com/en/general-information/registration/wherecaniregisterfrom'
									target='_blank'
								>
									HERE
								</a>
								.
							</li>
						</ul>
					</li>
				</ol>
				<p>
					(together an “<strong>Eligible Player</strong>”).
				</p>
				<ol start='3'>
					<li>
						To qualify for this Promotion, an Eligible Player must deposit and spend at least £10 in a single
						wager on any Website slot game within 7 days of registering for a Website account (a “
						<strong>Qualifying Wager</strong>”).
					</li>
					<li>
						Cumulative deposits and wagering up to £10 will not count towards a Qualifying Wager. No opt-in for
						this Promotion is required.
					</li>
					<li>
						Deposits made via Envoy, Neteller, PayPal, Apple Pay, Moneybookers, Paysafe, Skrill or via any
						pre-payment card, certain debit cards will NOT qualify for this Welcome Bonus.
					</li>
				</ol>
				<p>
					<strong>HOW TO CLAIM THE £10 SLOT BONUS </strong>
				</p>
				<ol start='7'>
					<li>
						Once a Qualifying Wager is made, an Eligible Player will be able to claim a £10 slot bonus that can be
						used on any Website slot excluding Jackpot Slots (the “<strong>£10 Slot Bonus</strong>”).
					</li>
					<li>
						The £10 Slot Bonus can be claimed by visiting the “My Bonuses” section of the “Promotions” tab on the
						Website.
					</li>
					<li>
						Once claimed, the £10 Slot Bonus will then be credited to the Bonus Balance section of an Eligible
						Player’s Website account. If the £10 Slot Bonus does not appear automatically, please contact our
						Customer Services team before playing again.
					</li>
					<li>
						The £10 Slot Bonus must be claimed, used and its Wagering Requirements (outlined below) met within
						seven (7) days of it becoming available in an Eligible Player’s Website account or it may be
						forfeited.
					</li>
					<li>
						Eligible Players will only be permitted to withdraw any remaining pending winnings from their “Bonus
						Balance” wallet and the “Pending Winnings” wallet once they have satisfied the Wagering Requirements
						explained below.
					</li>
					<li>
						Whilst you can withdraw the funds in your Cash Balance wallet at any time before the necessary
						Wagering Requirements have been met (whilst your £10 Slot Bonus is Active), this will mean that you
						fully forfeit any winnings sitting in your Bonus Balance wallet and/or your Pending Winnings wallet.
					</li>
				</ol>
				<p>
					<strong> WAGERING REQUIREMENTS FOR THE £10 SLOT BONUS</strong>
				</p>
				<p>
					<strong>The Wagering Requirements for the </strong>
					<strong>£10</strong> <strong>Slot Bonus are as follows:</strong>
				</p>
				<p>
					<strong>A WAGERING REQUIREMENT OF 40 TIMES THE </strong>
					<strong>£10</strong> <strong>SLOT BONUS AMOUNT = £400</strong>
				</p>
				<ol start='13'>
					<li>
						Therefore, an Eligible Player must wager £400 in bonus funds on any Website slot game to satisfy the
						£10 Slot Bonus Wagering Requirements.
					</li>
					<li>
						The £10 Slot Bonus Wagering Requirements must be met within&nbsp;
						<span style={{ 'text-decoration': 'underline' }}>seven (7) days</span> of the £10 Slot Bonus being
						credited to an Eligible Player’s Website account otherwise any funds sitting in an Eligible Player’s
						Bonus Balance wallet and/or Pending Winnings wallet will be forfeited.
					</li>
					<li>There is no cash alternative to the £10 Slot Bonus.</li>
				</ol>
				<p>
					<strong>HOW TO CLAIM THE 100 FREE SPINS</strong>
				</p>
				<ol start='16'>
					<li>
						Once a Qualifying Wager is made, an Eligible Player will be able to claim <strong>100 </strong>free
						spins that can only be used on the Website slot game “Starburst” (the “<strong>Free Spins</strong>”).
					</li>
					<li>
						The Free Spins can be claimed by visiting the “My Free Spins” section of the “Promotions” tab on the
						Website.
					</li>
					<li>The Free Spins are valued at £0.10 each.</li>
					<li>
						Once claimed, Free Spins will be automatically credited to the Eligible Player’s account. If the Free
						Spins do not appear automatically, please contact our Customer Services team before playing again.
					</li>
					<li>
						The Free Spins must be claimed and used within seven (7) days of them becoming available in an
						Eligible Player’s Website account or they may be forfeited.
					</li>
					<li>
						Winnings from the Free Spins will be paid as a bonus with Wagering Requirements (the “
						<strong>Free Spins Bonus</strong>”). Eligible Player’s will only be permitted to withdraw any funds in
						their Bonus Balance and Pending Winnings wallet once they have satisfied the Wagering Requirements
						explained below.
					</li>
				</ol>
				<p>
					<strong>
						<span style={{ 'text-decoration': 'underline' }}>WAGERING REQUIREMENTS FOR THE FREE SPINS BONUS</span>
					</strong>
				</p>
				<p>
					<strong>The Wagering Requirements for the Free Spins Bonus are as follows:</strong>
				</p>
				<p>
					<strong>A WAGERING REQUIREMENT OF 40 TIMES THE FREE SPINS BONUS</strong>
				</p>
				<ol start='22'>
					<li>
						For example, if an Eligible Players wins £2.00 in Free Spins Bonus, they would need to wager £80 in
						bonus funds to satisfy the Free Spins Bonus Wagering Requirements.
					</li>
					<li>Free Spins Bonus can be wagered on any Website slot game.</li>
					<li>
						The Free Spins Bonus Wagering Requirements must be met within{' '}
						<span style={{ 'text-decoration': 'underline' }}>seven (7) days</span> of the Free Spins being
						credited to an Eligible Player’s Website account, otherwise any funds sitting in an Eligible Player’s
						Bonus Balance wallet and/or Pending Winnings wallet will be forfeited.
					</li>
					<li>There is no cash alternative to the Free Spins.</li>
					<li>
						If an Eligible Player has no bonus funds left before completing the Wagering Requirements, this
						Promotion will end.
					</li>
				</ol>
				<p>
					<strong>IMPORTANT TERMS</strong>
				</p>
				<ol start='27'>
					<li>
						Promoter: LC International Limited, registered in Gibraltar and having its registered office at Suite
						6, Atlantic Suites, Europort Avenue, Gibraltar.
					</li>
					<li>
						The Promoter may place restrictions on an Eligible Player’s account, including deposit restrictions,
						in order to comply with our legal and regulatory obligations. The Promoter will not be responsible
						should these restrictions affect an Eligible Player’s ability to complete the requirements of this
						Promotion and/or to release any bonus, benefits or prizes.
					</li>
					<li>
						Where the Promoter suspects that an Eligible Player is guilty of fraudulent activity, the Promoter
						reserves the right to suspend or remove that player from the Promotion, and/or suspend that player’s
						Website account and/or suspend the Promotion pending further investigation.
					</li>
					<li>This Promotion cannot be used in conjunction with any other Website promotion.</li>
					<li>
						The Promoter reserves the right to change, end or restrict your access to any Promotion, if required
						for legal and/or regulatory reasons.
					</li>
					<li>
						The Promoter is aware that some links to their Welcome Offer Promotions may be posted on other sites
						or through forums or the information that the potential customer received may have been forwarded on
						to other persons who didn't receive the genuine Welcome Offer Promotions from them. If the Promoter
						determines, acting reasonably, that you did NOT receive this Promotion as an intended exclusive
						targeted recipient, the Promoter will not honour this Promotion.
					</li>
					<li>
						In the event of any error or system failure, or any error in the crediting of funds, winnings, or
						bonuses to an Eligible Player’s Website account in connection with this Promotion, the Promoter
						reserves the right to correct the error and withhold funds or winnings resulting from such error in
						accordance with its{' '}
						<a
							rel='noopener noreferrer'
							href='https://help.foxygames.com/en/legal-matters/general-terms-and-conditions/general'
							target='_blank'
						>
							General Terms and Conditions
						</a>
						.
					</li>
				</ol>
				<p>
					<strong> EXCLUDED GAMES</strong>
				</p>
				<ol start='34'>
					<li>
						The following games are excluded from this Welcome Bonus and any bets placed on any of these games
						will not count towards wagering requirements: Original Roulette, Key Bet Roulette, 25p Roulette, UK
						Roulette, Roulettex2, Roulette6, Jack or Better, all Slingo variants, Spin A Win, Sic Bo, Pontoon,
						Golden Tour, Scarab, Primate King, 10,001 Nights, Dynamite Riches,Icy Wilds, Wild Hot Chilli Reels,
						Cash Ultimate, Arcade Bomb, Diamond Blitz, Dragons Fire Infini Reels, Mega Rise, Wild O’Clock, all
						video Poker variants, all Blackjack variants and all Baccarat variants.
					</li>
				</ol>
			</div>
		</div>
	);
};

export default Content;

import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import Banner from '../../banner/Banner';
import Content from './content';

import bannerMobile from './images/banner-mobile.webp';
import banner from './images/banner.webp';

import { useLocation } from 'react-router-dom';
import '../../../variables.scss';
import './gala-bingo.scss';

const GalaSpins = ({ href }) => {
	const location = useLocation();
	const composeLink = () => {
		if (location.search.length > 0) return href + '&' + location.search.substring(1);
		return href;
	};

	const bannerHeaderStyles = {
		fontSize: { xs: '1.8rem', md: '3rem' },
		textAlign: { xs: 'center', md: 'left' },
		width: '100%',
		color: '#ffe330',
		fontStyle: 'italic',
		fontWeight: 700
	};
	const bannerSubHeaderStyles = {
		fontSize: { xs: '1.25rem', md: '1.75rem' },
		textAlign: { xs: 'center', md: 'left' },
		width: '100%',
		color: '#082ba6',
		fontStyle: 'italic',
		fontWeight: 700,
		flexGrow: 1
	};
	const stepBorder = { xs: '1px solid hsla(0,0%,100%,.3)', md: 'none' };

	return (
		<div className='gala-bingo'>
			<Helmet>
				<title>Gala Bingo | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner} bannerMobile={bannerMobile} noShadow>
				<Typography paragraph sx={bannerHeaderStyles}>
					£10 SLOTS BONUS
				</Typography>
				<Typography paragraph sx={bannerHeaderStyles}>
					& 100 FREE SPINS
				</Typography>
				<Typography paragraph sx={bannerSubHeaderStyles}>
					LET YOUR JOURNEY BEGIN
				</Typography>
				<Button
					href={composeLink()}
					variant='contained'
					size='large'
					className='pink text-white'
					sx={{ width: { xs: '100%', md: 'auto' } }}
				>
					Join here
				</Button>
			</Banner>
			<Grid container className='steps'>
				<Container
					sx={{
						py: '1em',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: { xs: 'column', md: 'row' }
					}}
				>
					<Grid item xs={12} md={4} className='step'>
						<Box sx={{ mx: '0.5em', textAlign: { sm: 'left', md: 'center' } }} className='text'>
							<span className='step-header text-white bold'>JOIN</span>
							<span className='step-desc text-white'>Gala and start your adventure.</span>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						className='step'
						sx={{
							borderTop: stepBorder,
							borderBottom: stepBorder
						}}
					>
						<Box sx={{ mx: '0.5em', textAlign: { sm: 'left', md: 'center' } }} className='text'>
							<span className='step-header text-white bold'>DEPOSIT & PLAY</span>
							<span className='step-desc text-white'>£5 on any slot game.</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<Box sx={{ mx: '0.5em', textAlign: { sm: 'left', md: 'center' } }} className='text'>
							<span className='step-header text-white bold'>GET</span>
							<span className='step-desc text-white'>
								A £10 Slots Bonus and 100 Free Spins to use within seven days on selected slots.
							</span>
						</Box>
					</Grid>
				</Container>
			</Grid>
			<Container sx={{ py: '1em' }}>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New customers only. Deposit & spend £5 to get £10 Slots Bonus (40x wagering, valid 7 days,
						selected games) + 100 Free Spins (£0.10 value each, selected games, valid for 7 days). Certain deposit
						methods excluded. Players restrictions and T&Cs apply. BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						href={composeLink()}
						variant='contained'
						size='large'
						className='pink text-white'
						sx={{ my: '10px' }}
					>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default GalaSpins;

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const OffersList = () => {
	return (
		<>
			<ul>
				<Helmet>
					<title>Offers List | WhichCasinos</title>
				</Helmet>
				<li>
					<Link to='/888'>888 Casino</Link>
				</li>
				<li>
					<Link to='/amazon-slots'>Amazon Slots</Link>
				</li>
				<li>
					<Link to='/betneptune'>BetNeptune</Link>
				</li>
				<li>
					<Link to='/cashmo'>Cashmo</Link>
				</li>
				<li>
					<Link to='/fortune-jackpots'>Fortune Jackpots</Link>
				</li>
				<li>
					<Link to='/mayfaircasino'>Mayfair Casino</Link>
				</li>
				<li>
					<Link to='/miami-jackpots'>Miami Jackpots</Link>
				</li>
				<li>
					<Link to='/mrspin'>Mr Spin</Link>
				</li>
				<li>
					<Link to='/partycasino'>Party Casino</Link>
				</li>
				<li>
					<Link to='/gala-spins'>Gala Spins (ID: 12093)</Link>
				</li>
				<li>
					<Link to='/gala-spins-2'>Gala Spins (ID: 12115)</Link>
				</li>
				<li>
					<Link to='/gala-spins-3'>Gala Spins (ID: 12103)</Link>
				</li>
				<li>
					<Link to='/gala-bingo'>Gala Bingo (ID: 12103)</Link>
				</li>
				<li>
					<Link to='/gala-bingo-2'>Gala Bingo (ID: 12115)</Link>
				</li>
				<li>
					<Link to='/foxy-games'>Foxy Games</Link>
				</li>
			</ul>
		</>
	);
};

export default OffersList;

import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-white'>
			<div className='panel'>
				<p>
					<strong>
						BEFORE CLAIMING THIS PROMOTION IT IS IMPORTANT THAT YOU READ THESE PROMOTIONAL TERMS AND CONDITIONS
						CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION ABOUT THE TERMS ON WHICH THIS PROMOTION CAN BE
						CLAIMED. YOU WILL BE REQUIRED TO AGREE TO THESE PROMOTIONAL TERMS &amp; CONDITIONS WHEN CLAIMING THIS
						PROMOTION. PLEASE FAMILIARISE YOURSELF WITH THE RESTRICTIONS, WITH PARTICULAR ATTENTION TO THE
						WAGERING REQUIREMENTS, SUMMARISED BELOW.
					</strong>
				</p>
				<p>
					These Terms &amp; Conditions apply to this “Spend £10 Get £20 Slots Bonus + 30 Free Spins” promotion
					(“the <strong>Promotion</strong>”). They should be read alongside our General Website Terms &amp;
					Conditions and Promotional Terms &amp; Conditions. These terms can be accessed{' '}
					<a
						rel='noopener noreferrer'
						href='https://help.galaspins.com/en/general-information/legal-matters/terms-and-conditions'
						target='_blank'
					>
						HERE
					</a>{' '}
					&amp;{' '}
					<a
						rel='noopener noreferrer'
						href='https://help.galaspins.com/en/general-information/legal-matters/jargon-buster'
						target='_blank'
					>
						HERE
					</a>
					.
				</p>
				<p>
					<strong>PROMOTIONAL PERIOD</strong>
				</p>
				<ol>
					<li>
						This Promotion is available to Eligible Players (defined below) from 2 March 2022 until 31 December
						2022.
					</li>
				</ol>
				<p>
					<strong>WHO QUALIFIES FOR THIS PROMOTION</strong>
				</p>
				<ol start='2'>
					<li>
						This Promotion is available to new customers of{' '}
						<a rel='noopener noreferrer' href='http://www.galaspins.com' target='_blank'>
							galaspins.com
						</a>{' '}
						(the “<strong>Website</strong>”) who:
						<ul>
							<li>are over 18 years old;</li>
							<li>have not made a deposit before;</li>
							<li>have a verified Website account; and</li>
							<li>
								have an account registered in a country where the Promoter accepts customers, which can be seen
								in the list{' '}
								<a
									rel='noopener noreferrer'
									href='https://help.galabingo.com/en/general-information/registration/wherecaniregisterfrom'
									target='_blank'
								>
									HERE
								</a>
								.
							</li>
						</ul>
					</li>
				</ol>
				<p>
					(together an “<strong>Eligible Player</strong>”).
				</p>
				<ol start='3'>
					<li>
						To qualify for this promotion, an eligible player must deposit £10 as their first deposit in a single
						transaction, then spend at least £10 on any slot game within 7 days of registering for an account.
					</li>
					<li>
						Cumulative deposits up to £10 will not count. You must make the full amount on your first deposit. No
						opt-in for this Promotion is required.
					</li>
					<li>
						This Promotion is not available to customers who open NEW accounts &amp; deposit funds with prepaid
						cards, Envoy, Neteller, PayPal, Apple Pay, MoneyBookers, Paysafe or Skrill.
					</li>
					<li>An Eligible Player can only claim this Promotion once.</li>
				</ol>
				<p>
					<strong>HOW TO CLAIM THE £20 SLOTS BONUS </strong>
				</p>
				<ol start='7'>
					<li>
						Once a Qualifying Wager is made, an Eligible Player will be able to claim a £20 slots bonus that can
						be used in any slot game excluding Jackpot Slots (the “<strong>£20 slots Bonus</strong>”).
					</li>
					<li>
						Selected games for this Welcome Bonus include: 9 Pots of Gold, Big Banker, Big Bass Bonanza, Big
						Fishing Fortune, Bigger Bass Bonanza, Cleopatra, Fishin' Frenzy, Fishin' Frenzy The Big Catch, Fishin'
						Frenzy The Big Catch Jackpot King, Fluffy Favourites, King Kong Cashpots Jackpot King, Lucky Larry
						Lobstermania Slingo, Rainbow Cashpots, Rainbow Riches Pots of Gold, Sahara Riches: Cash Collect,
						Slingo Rainbow Riches, Slingo Reel King, Slingo XXXtreme, and The Goonies Return
					</li>
					<li>
						The £20 slots bonus can be claimed by visiting the “My Bonuses” section of the “Promotions” tab on the
						Website.
					</li>
					<li>
						Once claimed, the £20 slots bonus will then be credited to the Bonus Balance section of an Eligible
						Player’s Website account. If the £20 slots bonus does not appear automatically, please contact our
						Customer Services team before playing again.
					</li>
					<li>
						The £20 slots bonus must be claimed, used and its Wagering Requirements (outlined below) met within
						seven (7) days of it becoming available in an Eligible Player’s Website account or it may be
						forfeited.
					</li>
					<li>
						Eligible Players will only be permitted to withdraw any remaining pending winnings from their “Bonus
						Balance” wallet and the “Pending Winnings” wallet once they have satisfied the Wagering Requirements
						explained below.
					</li>
					<li>
						Whilst you can withdraw the funds in your Cash Balance wallet at any time before the necessary
						Wagering Requirements have been met (whilst your £20 slots bonus is Active), this will mean that you
						fully forfeit any winnings sitting in your Bonus Balance wallet and/or your Pending Winnings wallet.
					</li>
				</ol>
				<p>
					<strong>
						<span style={{ textDecoration: 'underline' }}>WAGERING REQUIREMENTS FOR THE </span>
					</strong>
					<strong>
						<span style={{ textDecoration: 'underline' }}>£20 SLOTS BONUS</span>
					</strong>
				</p>
				<p>
					<strong>The Wagering Requirements for the </strong>
					<strong>£20 slots bonus </strong>
					<strong>are as follows:</strong>
				</p>
				<p>
					<strong>A WAGERING REQUIREMENT OF 40 TIMES THE </strong>
					<strong>£20 slots bonus</strong> <strong>AMOUNT = £800</strong>
				</p>
				<ol start='14'>
					<li>
						Therefore, an Eligible Player must wager £800 in bonus funds on selected Website slots games to
						satisfy the £20 slots bonus Wagering Requirements.
					</li>
					<li>
						The £20 slots bonus Wagering Requirements must be met within&nbsp;
						<span style={{ textDecoration: 'underline' }}>seven (7) days</span> of the £20 slots bonus being
						credited to an Eligible Player’s Website account otherwise any funds sitting in an Eligible Player’s
						Bonus Balance wallet and/or Pending Winnings wallet will be forfeited.
					</li>
					<li>There is no cash alternative to the £20 slots bonus.</li>
				</ol>
				<p>
					<strong>HOW TO CLAIM THE 30 FREE SPINS</strong>
				</p>
				<ol start='17'>
					<li>
						Once a Qualifying Wager is made, an Eligible Player will be able to claim 30 free spins that can only
						be used on Website slot game ‘Starburst’ (the “<strong>Free Spins</strong>”).
					</li>
					<li>
						The Free Spins can be claimed by visiting the “My Free Spins” section of the “Promotions” tab on the
						Website.
					</li>
					<li>The Free Spins are valued at £0.10 each.</li>
					<li>
						Once claimed, Free Spins will be automatically credited to the Eligible Player’s account. If the Free
						Spins do not appear automatically, please contact our Customer Services team before playing again.
					</li>
					<li>
						The Free Spins must be claimed and used within seven (7) days of them becoming available in an
						Eligible Player’s Website account or they may be forfeited.
					</li>
					<li>The Free Spins have no Wagering Requirements.</li>
					<li>Any winnings from the Free Spins will be paid as cash into an Eligible Player’s website account.</li>
				</ol>
				<p>
					<strong>IMPORTANT TERMS</strong>
				</p>
				<ol start='24'>
					<li>
						Promoter: LC International Limited, registered in Gibraltar and having its registered office at Suite
						6, Atlantic Suites, Europort Avenue, Gibraltar.
					</li>
					<li>
						The Promoter may place restrictions on an Eligible Player’s account, including deposit restrictions,
						in order to comply with our legal and regulatory obligations. The Promoter will not be responsible
						should these restrictions affect an Eligible Player’s ability to complete the requirements of this
						Promotion and/or to release any bonus, benefits or prizes.
					</li>
					<li>
						Where the Promoter suspects that an Eligible Player is guilty of fraudulent activity, the Promoter
						reserves the right to suspend or remove that player from the Promotion, and/or suspend that player’s
						Website account and/or suspend the Promotion pending further investigation.
					</li>
					<li>This Promotion cannot be used in conjunction with any other Website promotion.</li>
					<li>
						The Promoter reserves the right to change, end or restrict your access to any Promotion, if required
						for legal and/or regulatory reasons.
					</li>
					<li>
						The Promoter is aware that some links to their Welcome Offer Promotions may be posted on other sites
						or through forums or the information that the potential customer received may have been forwarded on
						to other persons who didn't receive the genuine Welcome Offer Promotions from them. If the Promoter
						determines, acting reasonably, that you did NOT receive this Promotion as an intended recipient, the
						Promoter will not honour this Promotion.
					</li>
					<li>
						In the event of any error or system failure, or any error in the crediting of funds, winnings, or
						bonuses to an Eligible Player’s Website account in connection with this Promotion, the Promoter
						reserves the right to correct the error and withhold funds or winnings resulting from such error in
						accordance with its{' '}
						<a
							rel='noopener noreferrer'
							href='https://help.galaspins.com/en/general-information/legal-matters/terms-and-conditions'
							target='_blank'
						>
							General Terms and Conditions
						</a>
						.
					</li>
				</ol>
			</div>
		</div>
	);
};

export default Content;

import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>
			<ol>
				<li style={{ 'list-style-type': 'none' }}>
					<ol>
						<li>1st deposit offer: 25 Free Spins on Gonzo’s Quest</li>
						<li>2nd deposit offer: 50% Welcome Bonus up to £/€/$ 100</li>
						<li>3rd deposit offer: 25% Welcome Bonus up to £/€/$ 200 + 25 Free Spins on Reactoonz</li>
					</ol>
				</li>
			</ol>
			<p>
				Bonus or any winnings from the free spins must be wagered 50 times before a withdrawal may be made. Wagering
				contributions apply and vary from game to game.
			</p>
			<p>Matched Bets &amp; Free Spins Terms &amp; Conditions:</p>
			<ol>
				<li style={{ 'list-style-type': 'none' }}>
					<ol>
						Match Deposit Offer:
						<p></p>
						<li>
							Any bonus and winnings will expire 30 days after being credited from the date and time it is
							credited to a player’s account. Players who have not used their bonus and winnings generated from
							the bonus or if the Wagering Requirement has not been met within the stipulated time limits shall
							not be able to receive the expired bonus and/or winnings generated from the bonus thereafter.
						</li>
						<li>
							Once the amount you have wagered meets or exceeds the Wagering Requirement, the Promotion will end
							and your Bonus Funds will automatically transfer to your Real Money Balance (up to the Maximum
							Conversion Amount of 3 times the original bonus amount credited to your account) from where you
							will be able to withdraw them as cash or continue to use them in non-Promotion play.
						</li>
					</ol>
				</li>
			</ol>
			<p>Free Spins Offer:</p>
			<ol>
				<li>
					Any bonus and winnings will expire 7 days after being credited from the date and time it is credited to a
					player’s account. Players who have not used their bonus and winnings generated from the bonus or if the
					Wagering Requirement has not been met within the stipulated time limits shall not be able to receive the
					expired bonus and/or winnings generated from the bonus thereafter.
				</li>
				<li>
					Once the amount you have wagered meets or exceeds the Wagering Requirement, the Promotion will end and
					your Bonus Funds will automatically transfer to your Real Money Balance (up to the Maximum Conversion
					Amount of £/€/$ 20) from where you will be able to withdraw them as cash or continue to use them in
					non-Promotion play.
				</li>
			</ol>
			<p>General Terms &amp; Conditions:</p>
			<ol>
				<li>The promotion is not available to customers depositing with Skrill payment method.</li>
				<li>
					The promotion is subject to the BetNeptune’s{' '}
					<a
						title='Terms and Conditions'
						href='https://betneptune.casino-pp.net/?lang=en-in&amp;tracker=211021&amp;dynamic={click}#info/terms'
						target='_blank'
						rel='noreferrer'
					>
						Terms and Conditions
					</a>
					.
				</li>
				<li>
					BetNeptune reserves the right to alter this chapter, cancel, modify or suspend any offer and any
					promotion at any time and without prior notice – in respect of any Bonus that has yet to be provided to
					You. Any bonuses granted prior to the change shall not be affected. The terms and conditions and the
					bonus policy that apply to any Bonus received by You are the Terms and Conditions and its Bonus Policy
					that are in force at the time in which You sign up to the Promotion to which that Bonus relates. Nothing
					in this section limits any other right and/or remedy granted to Us.
				</li>
				<li>
					These terms and conditions may be published in a number of languages for information purposes. In case of
					deviations, the English version applies.
				</li>
			</ol>
		</div>
	);
};

export default Content;

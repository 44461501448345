import React, { useState } from 'react';
import Footer from '../footer';
import MainBar from '../mainbar';
import Header from '../header';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './App.scss';
import Routes from './routes';

function App() {
	const [mainBg, setMainBg] = useState('#fff');
	const theme = createTheme({
		palette: {
			mode: 'dark'
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Router hashType='noslash'>
				<MainBar />
				<Header setMainBg={setMainBg} />
				<main style={{ backgroundColor: mainBg }}>
					<Routes />
				</main>
				<Footer />
			</Router>
		</ThemeProvider>
	);
}

export default App;

import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.png';

import '../../../variables.scss';
import './cashmo.scss';

const Cashmo = ({ href }) => {
	return (
		<div className='cashmo'>
			<Helmet>
				<title>Cashmo | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='big'>WELCOME PACKAGE</p>
				<p className='bigger'>UP TO £/€/$ 300 + 50 FREE SPINS</p>
				<p className='big'>on Gonzo's Quest & Reactoonz</p>
				<Button href={href} variant='contained' size='large' className='green text-white' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>JOIN TODAY</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>DEPOSIT</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>PLAY!</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New players only. Up to 50 (18p) locked free spins to use on Rainbow Slots. Win paid as bonus
						credit. 40x wagering requirement. 7 day expiry. £50 Max withdrawal from bonus win. T&Cs apply.
						Begambleaware.org.
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='green text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default Cashmo;

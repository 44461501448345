import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-white'>
			<h1>Terms and Conditions</h1>
			<ul>
				<li>
					<span>"Free Spins" refers to spins on regular slot games.</span>
				</li>
				<li>
					<span>
						The Welcome offer: You will receive a FREE spin of the "Mega Reel" when you make your first deposit.
						Prizes vary, ranging from Free Spins to Amazon Vouchers.
					</span>
				</li>
				<li>
					<span>You will either be invited to play for FREE or pay a small amount to spin the Reel.</span>
				</li>
				<li>
					<span>
						The Free Spins advertised are only available on the following games: Starburst and Fluffy Favourites.
						Free Spins are awarded via the Mega Reel. The Mega Reel is a game of chance; winning a prize is not
						guaranteed. The minimum winning prize from the offer advertised is 20 Free Spins on Fluffy Favourites
						and the maximum winning prize is 500 Free Spins on Starburst.&nbsp;
					</span>
				</li>
				<li>
					<span>
						Amazon vouchers won from the Mega Reel will be emailed to the address registered on your account
						within 7 days. We are not liable for the non-receipt of the vouchers. You are responsible for ensuring
						the email address on your account is correct. Vouchers will not be resent for any reason.
					</span>
				</li>
				<li>
					<span>
						If you chose to close the "Mega Reel" pop-up, this is your choice and the game will not be made
						available or be re-credited to your account until the next claim.
					</span>
				</li>
				<li>
					<span>
						Wins resulting from Free Spins are credited to your account as "Bonus" cash. Winnings from Free Spins
						are subject to a maximum win amount of £8 per 10 free spins. For example, the maximum amount of bonus
						cash that can be won from 20 free spins is £16. Due to maximum win amounts, you may not receive all
						the spins which are displayed as remaining to play.
					</span>
				</li>
				<li>
					<span>
						If you experience any issues with claiming the mega reel and/or free spins, please get in touch with
						the support team who will look at your request on a case by case basis.
					</span>
				</li>
				<li>
					<span>
						Bonuses are not automatically credited after depositing via the "Quick Deposit" option. Please contact
						customer support in order to claim.
					</span>
				</li>
				<li>
					<span>
						The following applies to all Bonus funds obtained on the site: the wagering requirement is 65 x bonus
						won + any remaining wagering requirement. If you decide to cash-out (withdraw) before fulfilling the
						wagering requirement, you will forfeit all remaining bonus funds, free spins, Loot Chest plays and
						Mega Reel spins. For example, if you win £10 bonus from free spins from a £20 deposit and your current
						wagering requirement is £100, this will result in a wagering requirement of £10 X 65 + £100 = £750. If
						there is no existing bonus in your balance or wagering requirement and you win £10 bonus from free
						spins from a £20 deposit, the wagering requirement is calculated as £10 x 65 = £650.
					</span>
				</li>
				<li>
					<span>
						Only bonus wagers contribute towards fulfilling the wagering requirement. Real money wagers do not
						contribute.
					</span>
				</li>
				<li>
					<span>
						You can withdraw your real money balance (subject to withdrawal policy) but Bonus funds can never be
						withdrawn.
					</span>
				</li>
				<li>
					<span>
						The maximum amount (for accounts that have made a deposit) which can be converted to your Real Money
						(withdrawable) balance as a result of fulfilling the bonus wagering requirement, is the value of your
						lifetime deposits on this website, up to a maximum of £250. For example, if you have accumulated bonus
						funds of £400 once the wagering requirement has been fulfilled and your total lifetime deposits are
						£50, then £50 will be transferred to your real money bankroll. The remaining bonus balance will be
						removed permanently.
					</span>
				</li>
				<li>
					<span>
						The maximum bonus conversion for accounts that have not made a deposit is £50. E.g. if you have
						accumulated bonus funds of £400 once the wagering requirement has been fulfilled, £50 will be
						transferred to your real money bankroll. The remaining bonus balance will be removed permanently.
					</span>
				</li>
				<li>
					<span>
						Winnings generated from your bonus balance will be accumulated to the bonus balance bankroll. Once the
						wagering requirements (65x) have been met, the remaining bonus (maximum £250) will be transferred to
						your real (withdrawable) bankroll. All withdrawals are subject to the banking policy included in these
						terms and conditions.
					</span>
				</li>
				<li>
					<span>
						Winnings are credited to the balance from where the wager was taken. If in a single transaction you
						wager 50% with real money and 50% with bonus funds, any winnings will be credited to match the
						proportion of the original wager. E.g. A £3 total wager (£1.50 Real and £1.50 Bonus balance) results
						in a £10 win, this means £5 will be credited to each balance.
					</span>
				</li>
				<li>
					<span>
						Real funds will always be used to wager before bonus funds. You will only use your bonus balance when
						you do not have enough real money to place the wager.
					</span>
				</li>
				<li>
					<span>
						The following games do not contribute towards fulfilling the wagering requirement: Blackjack (all
						variants), Roulette (all variants), any other casino style games and progressive jackpot slots. The
						company reserves the right to alter this at any time and without notice. Any changes in this regard
						will not impact any existing bonus or offers which have been offered to you, or have been already
						claimed.
					</span>
				</li>
				<li>
					<span>
						Bonus money wagering is not available on progressive jackpot slots, including (but not limited to):
						"Fluffy Favourites Jackpot" and "Fluffy Too Jackpot"
					</span>
				</li>
				<li>
					<span>You can track your remaining wagering requirement on the withdrawal page of the website.</span>
				</li>
				<li>
					<span>
						The company reserves the right to amend the wagering requirements on bonus funds at its sole
						discretion. Any changes in this regard will not impact any existing bonus or offers which have been
						offered to you, or have been already claimed.
					</span>
				</li>
				<li>
					<span>Regular site T&amp;Cs apply</span>
				</li>
			</ul>
		</div>
	);
};

export default Content;

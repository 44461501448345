import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.jpg';

import '../../../variables.scss';
import './miami-jackpots.scss';

const MiamiJackpots = ({ href }) => {
	return (
		<div className='miamijackpots'>
			<Helmet>
				<title>Miami Jackpots | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='text-brown bigger'>100% up to £50</p>
				<p className='white-text big'>+ 50 Free Spins</p>
				<p className='text-brown'>BONUS CODE: MJ100</p>
				<Button href={href} variant='contained' size='large' className='brown text-white' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. First Deposit Only. Min. deposit: £10, max. Bonus £50. Game: Book of Dead, Spin Value: £0.10. WR
						of 30x Deposit + Bonus amount and 60x Free Spin winnings amount (only Slots count) within 30 days. Max
						bet is 10% (min £0.10) of the free spin winnings and bonus amount or £5 (lowest amount applies). Spins
						must be used and/or Bonus must be claimed before using deposited funds. First Deposit/Welcome Bonus
						can only be claimed once every 72 hours across all Casinos. Bonus Policy applies. T&Cs apply.
						BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='brown text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default MiamiJackpots;

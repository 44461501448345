import { Helmet } from 'react-helmet';
import { Box, Button, Container, Alert, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.jpg';
import offerImage from './images/offer.png';

import '../../../variables.scss';
import './amazon-slots.scss';
import { useLocation } from 'react-router-dom';

const AmazonSlots = ({ href }) => {
	const location = useLocation();
	const composeLink = () => {
		if (location.search.length > 0) return href + '&' + location.search.substring(1);
		return href;
	};

	return (
		<div className='amazon-slots'>
			<Helmet>
				<title>Amazon Slots | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<Box className='offerImageBlock'>
					<img src={offerImage} alt='' />
				</Box>
				<Button
					href={composeLink()}
					variant='contained'
					size='large'
					className='green text-white'
					style={{ marginTop: 10 }}
				>
					Claim my spins
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>Register</span>
							<span className='step-desc text-white'>It only takes 2 minutes</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>Deposit</span>
							<span className='step-desc text-white'>At least £10 and spin the Mega reel</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>WIN</span>
							<span className='step-desc text-white'>Up to 500 free spins on Starburst</span>
						</Box>
					</Grid>
				</Grid>
				<Alert severity='info'>
					18+, NEW PLAYERS ONLY, MIN DEPOSIT £10.00, £8.00 MAX WIN PER 10 SPINS, MAX BONUS EQUAL TO LIFETIME
					DEPOSITS (UP TO £250.00). FREE SPINS CAN BE WON ON THE FOLLOWING GAMES: STARBURST AND FLUFFY FAVOURITES.
					65X WAGERING REQUIREMENTS AND FULL T&CS APPLY{' '}
					<a href={`${href}#page-block-akqzzczf40b`} tar>
						HERE
					</a>
					. BeGambleAware.org
				</Alert>
				<Content />
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						href={composeLink()}
						variant='contained'
						size='large'
						className='green text-white'
						sx={{ my: '10px' }}
					>
						Claim my spins
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default AmazonSlots;

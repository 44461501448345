import { useLocation } from 'react-router';
import plus18 from '../footer/footer-logos/images/18plus.svg';
import whichcasinos from '../footer/footer-logos/images/whichcasinos.png';

import logo888 from '../pages/888/images/logo.png';
import logoAmazonSlots from '../pages/amazon-slots/images/logo.png';
// import navAmazonSlots from '../pages/amazon-slots/images/nav-bg.jpg';
import betNeptune from '../pages/betneptune/images/logo.png';
import cashmo from '../pages/cashmo/images/logo.png';
import fortuneJackpots from '../pages/fortune-jackpots/images/logo.svg';
import foxyGames from '../pages/foxy-games/images/logo.png';
import galaBingo from '../pages/gala-bingo/images/logo.svg';
import galaSpins from '../pages/gala-spins/images/logo.svg';
import mayfairCasino from '../pages/mayfaircasino/images/logo.png';
import miamiJackpots from '../pages/miami-jackpots/images/logo.svg';
import mrSpin from '../pages/mrspin/images/logo.png';
import partyCasino from '../pages/partycasino/images/logo.png';

import './Header.scss';

const Header = ({ setMainBg }) => {
	const location = useLocation();

	let background, logo, alt;

	switch (location.pathname) {
		case '/':
			background = '#156da0';
			setMainBg('#fff');
			logo = whichcasinos;
			alt = 'WhichCasinos';
			break;
		case '/888':
			background = '#000';
			setMainBg('#000');
			logo = logo888;
			alt = '888 Casino';
			break;
		case '/amazon-slots':
			// background = `url(${navAmazonSlots}) repeat-x bottom`;
			background = '#051d22';
			setMainBg('#051d22');
			logo = logoAmazonSlots;
			alt = 'Amazon Slots';
			break;
		case '/betneptune':
			background = '#000';
			setMainBg('#000');
			logo = betNeptune;
			alt = 'BetNeptune';
			break;
		case '/cashmo':
			background = '#00173e';
			setMainBg('#282b37');
			logo = cashmo;
			alt = 'Cashmo';
			break;
		case '/fortune-jackpots':
			background = '#000';
			setMainBg('#000');
			logo = fortuneJackpots;
			alt = 'Fortune Jackpots';
			break;
		case '/mayfaircasino':
			background = '#000';
			setMainBg('#000');
			logo = mayfairCasino;
			alt = 'Mayfair Casino';
			break;
		case '/miami-jackpots':
			background = '#1E1E1E';
			setMainBg('#1E1E1E');
			logo = miamiJackpots;
			alt = 'Mayfair Casino';
			break;
		case '/mrspin':
			background = '#082836';
			setMainBg('#F7F3FB');
			logo = mrSpin;
			alt = 'Mr Spin';
			break;
		case '/partycasino':
			background = '#211551';
			setMainBg('#110540');
			logo = partyCasino;
			alt = 'Party Casino';
			break;
		case '/gala-spins':
		case '/gala-spins-2':
		case '/gala-spins-3':
			background = '#2b3151';
			setMainBg('#2b3151');
			logo = galaSpins;
			alt = 'Gala Spins';
			break;
		case '/gala-bingo':
		case '/gala-bingo-2':
			background = '#082ba6';
			setMainBg('#f0f2f8');
			logo = galaBingo;
			alt = 'Gala Bingo';
			break;
		case '/foxy-games':
			background = '#0a258b';
			setMainBg('#ffffff');
			logo = foxyGames;
			alt = 'Foxy Games';
			break;
		default:
			background = 'transparent';
			setMainBg('#fff');
			logo = whichcasinos;
			alt = 'WhichCasinos logo';
	}

	const style = { background };
	return (
		<header className='header' style={style}>
			<div className='logo'>
				<img src={logo} alt={alt} className='header-image' />
				<div className='plus18-logo'>
					<img src={plus18} alt='WhichCasinos logo' className='header-image' />
				</div>
			</div>
		</header>
	);
};

export default Header;

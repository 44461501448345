import { Alert, Box, Button, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.webp';

import { useLocation } from 'react-router-dom';
import '../../../variables.scss';
import './gala-spins.scss';

const GalaSpins = ({ href }) => {
	const location = useLocation();
	const composeLink = () => {
		if (location.search.length > 0) return href + '&' + location.search.substring(1);
		return href;
	};

	return (
		<div className='gala-spins'>
			<Helmet>
				<title>Gala Spins | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='bigger'>PUT YOUR SPIN </p>
				<p className='bigger'>ON A £20 BONUS AND 30 FREE SPINS!</p>
				<p className='big'>WHEN YOU SPEND £10</p>
				<Button href={composeLink()} variant='contained' size='large' className='gold' style={{ marginTop: 10 }}>
					Join here
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>JOIN GALA SPINS</span>
							<span className='step-desc text-white'>It’s faff-free!</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>DEPOSIT AND SPEND</span>
							<span className='step-desc text-white'>£10 on ANY slot game.</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>£20 BONUS & 30 FREE SPINS</span>
							<span className='step-desc text-white'>Sent straight to your account!</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New customers only. Min deposit & spend £10 to get £20 bonus (40x wagering, selected games). 30
						Free Spins (value £0.10 each, selected games). Bonus & Free Spins valid for 7 days. Payment
						restrictions & T&Cs apply. BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={composeLink()} variant='contained' size='large' className='gold' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default GalaSpins;

import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.jpg';

import '../../../variables.scss';
import './foxy-games.scss';
import { useLocation } from 'react-router-dom';

const FoxyGames = ({ href }) => {
	const location = useLocation();
	const composeLink = () => {
		if (location.search.length > 0) return href + '&' + location.search.substring(1);
		return href;
	};

	return (
		<div className='foxy-games'>
			<Helmet>
				<title>Foxy Games | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p className='bigger'>SPEND £10, GET £10 BONUS</p>
				<p className='bigger'>+ 100 FREE SPINS!</p>
				<p className='big'>AN OUT OF THIS WORLD WELCOME</p>
				<Button href={composeLink()} variant='contained' size='large' className='gold' style={{ marginTop: 10 }}>
					Join here
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>DEPOSIT & SPEND £10</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>GET A £10 SLOT BONUS</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>PLUS 100 FREE SPINS ON STARBURST</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New verified customers only. Dep & spend £10 within 7 days of registration to get £10 Slot Bonus
						(40x wag, game exclusions apply, see terms for list, max stake £5, non-jackpot games only, claim and
						use within 7 days) + 100 Free Spins on Starburst (value £0.10, 40x wagering on winnings, claim and use
						within 7 days). Certain deposit methods excluded. Player restrictions and T&Cs apply.
						BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={composeLink()} variant='contained' size='large' className='gold' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default FoxyGames;

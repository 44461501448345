import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.png';

import '../../../variables.scss';
import './mrspin.scss';

const MrSpin = ({ href }) => {
	return (
		<div className='mrspin'>
			<Helmet>
				<title>Mr Spin | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<p>up to</p>
				<p className='huge'>50</p>
				<p className='big'>FREE SPINS</p>
				<Button href={href} variant='contained' size='large' className='green text-white' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header bold' style={{ color: '#082836' }}>
								Join Today
							</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header bold' style={{ color: '#082836' }}>
								Get Bonus
							</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header bold' style={{ color: '#082836' }}>
								Start Playing!
							</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New Players only. Up to 50 (20p) free spins locked to Reel Royalty. Win paid as bonus credit. 20x
						wagering requirement. 14 day expiry. £50 Max withdrawal from bonus win. T&Cs apply. BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='green text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default MrSpin;

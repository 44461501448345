import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>
			<ol>
				<li>
					The following are the terms and conditions of the "First Deposit Bonus Offer" (the "Offer") for{' '}
					<a href='https://www.888casino.com/' target='_blank' rel='noreferrer'>
						888casino.com
					</a>{' '}
					(the "Site"). The Offer is organized by the company defined in the User Agreement applicable to you.
				</li>
				<li>
					This is an ongoing promotion, currently without an end date. We will provide reasonable notice of when
					the promotion is to come to an end (the "Promotion Period").
				</li>
				<li>
					This promotion is applicable for new 888casino players only (you may not participate in the Offer if you
					have previously registered with the Site) and is limited to one (1) person per household.
				</li>
				<li>You may only enter this Offer once.</li>
				<li>
					No-one under the age of 18 or the age of legal consent for engaging in the gambling services made
					available through the Site under the laws of any jurisdiction, whichever is higher, may participate in
					this Offer.
				</li>
				<li>
					You may not combine this Offer with any other type of welcome offer or first-time deposit offer on the
					Site.
				</li>
				<li>Deposits made with Skrill or NETELLER [payment methods] are not eligible for the Welcome Bonus.</li>
				<li>
					Under the terms of this Offer, eligible players who deposit £20 or more will be entitled to a bonus in
					the amount of 100% of their deposit up to total of £100 ("Bonus") when they make their first-time deposit
					at the Site.
				</li>
				<li>
					The Bonus is only credited and valid if claimed within 48 hours of 888casino sending the claim email, by
					clicking on the "claim" link in the email.
				</li>
				<li>Bonus expires within 90 days of granting.</li>
				<li>
					The Bonus must be wagered 30 times within 90 days from granting. If you choose to withdraw your original
					deposit amount before completing the wagering requirements as aforementioned in this section, the bonus
					(and any applicable related winnings) will be cancelled.{' '}
					<a
						href='https://www.888casino.com/promotions/bonus-policy/#WageringTable'
						target='_blank'
						rel='noreferrer'
					>
						{' '}
						Wagering contributions apply
					</a>
					. See Wagering Contributions Table.
				</li>
				<li>
					With the exception of verified progressive jackpot wins, wins generated through the Bonus are capped at
					£500.
				</li>
				<li>Bonus may be used on selected games only.</li>
				<li>If you play from Northern Ireland, this promotion does not apply to you.</li>
				<li>
					In order to meet the applicable wagering requirement, no real money shall be deemed to contribute (in
					full or in part) to the wagering requirement, unless otherwise specified by us.
				</li>
				<li>
					Available funds will always be used before Restricted Funds (to include Money transferred) and the
					balance of both will be displayed in your My Account.
				</li>
				<li>
					All Bonus types (incl. Vouchers, their related winnings, and unclaimed Bonus funds) will be forfeited
					when making a withdrawal.
				</li>
				<li>
					Withdrawing funds: For further information on how you can withdraw funds from your account, please see
					our{' '}
					<a href='https://www.888casino.com/banking/withdrawal/policy/' target='_blank' rel='noreferrer'>
						Withdrawal Policy
					</a>
					. If you benefit from this Offer you will not be eligible to benefit from any other first deposit bonus
					or first deposit offer with the Site. If you have benefited from another first deposit offer with the
					Site you are not eligible to benefit from this Offer.
				</li>
				<li>
					If you have a pending message on the Site’s cashier regarding your recent gaming activity, we reserve the
					right to revoke your eligibility to participate and/or continue to participate in this Offer. In such
					event, any bonus and/or other benefit which you have received and/or are eligible to receive under this
					Offer, shall be forfeited by you.
				</li>
				<li>This Offer cannot be combined with any other similar promotion or offer.</li>
				<li>
					All bonuses and promotional offers, and any winnings generated through the wagering of either of the
					aforesaid, will be cancelled at withdrawal if any of the applicable conditions have not been met in full.
				</li>
				<li>
					888casino may cancel, modify or suspend the Offer, and any of the bonuses or benefits offered hereunder,
					including without limitation, where required for security reasons, to comply with applicable law or
					regulation, due to abuse or otherwise where the promotion is not capable of being conducted as specified
					as a result of exceptional circumstances. Other than where necessary to prevent fraud or other similar
					unlawful behavior, any such cancelation, modification, or suspension will not apply to any player that
					has opted into the Offer, made a deposit in expectation of receipt of a bonus or other benefits offered
					hereunder, and/or commenced play in the Offer prior to the date of the communication of the cancelation,
					modification or suspension.
				</li>
				<li>
					By registering you hereby agree to these terms and conditions which govern the Offer, including the{' '}
					<a
						href='https://www.888casino.com/security-and-privacy/user-agreement/'
						target='_blank'
						rel='noreferrer'
					>
						User Agreement
					</a>{' '}
					and the{' '}
					<a href='https://www.888casino.com/promotions/bonus-policy/' target='_blank' rel='noreferrer'>
						Bonus Policy
					</a>
					.
				</li>
			</ol>
		</div>
	);
};

export default Content;

import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.png';
import bannerMobile from './images/banner-mobile.png';

import '../../../variables.scss';
import './partycasino.scss';

const PartyCasino = ({ href }) => {
	return (
		<div className='partycasino'>
			<Helmet>
				<title>Party Casino | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner} bannerMobile={bannerMobile}>
				<p className='big'>Get the party started with</p>
				<p className='bigger'>50 FREE SPINS</p>
				<p className='bigger'>ON STARBURST</p>
				<Button href={href} variant='contained' size='large' className='green text-white' style={{ marginTop: 10 }}>
					Join now!
				</Button>
			</Banner>

			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>SIGN UP</span>
							<span className='step-desc text-white'>Register your details to join</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>DEPOSIT</span>
							<span className='step-desc text-white'>Make your first cash deposit</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-white bold'>PLAY</span>
							<span className='step-desc text-white'>Enjoy your free spins</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New depositors only. UK residents only. Minimum deposit £10. Free Spins are valid only on
						Starburst and expire in 7 days. 10x wagering apply. Certain deposit types are excluded. T&Cs apply.
						BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='green text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default PartyCasino;

import { Helmet } from 'react-helmet';
import { Alert, Box, Button, Container, Grid } from '@mui/material';
import Banner from '../../banner/Banner';
import Content from './content';

import banner from './images/banner.jpg';
import image from './images/banner.png';

import '../../../variables.scss';
import './fortune-jackpots.scss';

const FortuneJackpots = ({ href }) => {
	return (
		<div className='fortune'>
			<Helmet>
				<title>Fortune Jackpots | WhichCasinos</title>
			</Helmet>
			<Banner banner={banner}>
				<Box sx={{ display: 'flex', height: '100%' }}>
					<Box
						sx={{
							background: { xs: `url(${image}) no-repeat bottom`, md: 'none' },
							my: 'auto'
						}}
					>
						<p className='big'>WELCOME PACKAGE</p>
						<p className='bigger'>UP TO £/€/$ 300 + 50 FREE SPINS</p>
						<p className='big'>on Gonzo's Quest & Reactoonz</p>
						<Button
							href={href}
							variant='contained'
							size='large'
							className='green text-white'
							style={{ marginTop: 10 }}
						>
							Join now!
						</Button>
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						<img src={image} alt='' style={{ height: '100%' }} />
					</Box>
				</Box>
			</Banner>
			<Container sx={{ py: '1em' }}>
				<Grid container className='steps'>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>1</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>1ST DEPOSIT</span>
							<span className='step-desc text-white'>100% up to £50 + 20 Spins</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>2</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>2ND DEPOSIT</span>
							<span className='step-desc text-white'>50% Up to £50 + 40 SPINS</span>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} className='step'>
						<div className='number'>3</div>
						<Box sx={{ mx: '0.5em' }} className='text'>
							<span className='step-header text-blue bold'>3RD DEPOSIT</span>
							<span className='step-desc text-white'>50% up to £100 + 40 spins</span>
						</Box>
					</Grid>
				</Grid>
				<Container sx={{ py: '1em' }}>
					<Alert severity='info'>
						18+. New Players Only. Minimum deposit: £20. Welcome Package split over 4 deposits on different days.
						Wagering Requirement: 35x. Spins expire after 24 hours. Spins credited in specific games. Terms and
						Conditions apply. BeGambleAware.org
					</Alert>
					<Content />
				</Container>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button href={href} variant='contained' size='large' className='green text-white' sx={{ my: '10px' }}>
						Join now!
					</Button>
				</Box>
			</Container>
		</div>
	);
};
export default FortuneJackpots;

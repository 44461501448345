import '../../../../variables.scss';

const Content = () => {
	return (
		<div className='content text-grey-light'>
			<h1>Terms and Conditions</h1>
			<p>
				The promoter of this promotion is Entain&nbsp; registered in Gibraltar under the number 112454 and having
				its registered office at Suite 6, Atlantic Suites, Europort Avenue, Gibraltar.
			</p>
			<p>
				<strong>Specific Terms and Conditions</strong>
			</p>
			<ol>
				<li>
					The promotion will run from 00:00 (CEST) on 31.12.2019 to 23:59 (CEST) on 31.12.2021(the "Promotional
					Period").
				</li>
				<li>This offer is not available in conjunction with any other offer.</li>
				<li>
					By taking part in this promotion, you hereby agree to these Specific Promotional Terms and Conditions and
					to our General Terms and Conditions at&nbsp;
					<a
						href='https://help.partycasino.com/en/general-information/legal-matters/general-terms-and-conditions?promobanner=true&amp;productId=CASINO'
						target='_blank'
						rel='noreferrer'
					>
						https://help.partycasino.com/en/general-information/legal-matters/general-terms-and-conditions
					</a>
					.
				</li>
			</ol>
			<p>
				<strong>Welcome offer: 50 Free Spins</strong>
			</p>
			<ol start={4}>
				<li>
					In order to participate in this promotion, an Eligible Player (as defined below) must place an Eligible
					Deposit (as defined below) during the Promotional Period.
				</li>
				<li>
					Players must:
					<ol>
						<li>be over 18 years old;</li>
						<li>be located in the UK ;</li>
						<li>be a new player on www.partycasino.com.</li>
					</ol>
				</li>
			</ol>
			<p>(an “Eligible Player”)</p>
			<ol start={6}>
				<li>
					An Eligible Player must place deposit which comply with the following requirements:
					<ol>
						<li>
							the deposit is the first deposit placed by the Eligible Player via their player account on&nbsp;
							<a
								href='http://trk.vip-media.net/aff_c?offer_id=629&aff_id=12081'
								target='_blank'
								rel='noreferrer'
							>
								www.partycasino.com
							</a>
							;
						</li>
						<li>deposit must be of a minimum £10 ;</li>

						<li>
							Deposits made via PayPal, PaysafeCard, LuxonPay, Skrill1Tap, Neteller, InstantBank (Trustly), Apple
							Pay, PCard, MuchBetter and all credit cards shall not be eligible for this promotion (together, an
							“Eligible Deposit”)
						</li>
					</ol>
				</li>
			</ol>
			<p>&nbsp;(together, an “Eligible Deposit”)</p>
			<ol start={7}>
				<li>Eligible Players who place an Eligible Deposit will be entitled to 50 Free Spins on Starburst.</li>
			</ol>
			<p>
				<strong>Free Spin Terms and Conditions:</strong>
			</p>
			<ol start={8}>
				<li>
					50 Free Spins on the Starburst slot (the “Free Spins”) shall be credited to the Eligible Player’s account
					once a minimum deposit of £10 has been placed.
				</li>
				<li>
					Eligible Players must activate the Free Spins via the ‘My Free Spins’ section of their account within 7
					days of the day the Free Spins are credited to the Eligible Player’s account.
				</li>
				<li>
					If the Eligible Player does not claim their Free Spins within the 7 days, the Free Spins shall expire and
					be removed from the Eligible Player’s account.
				</li>
				<li>The Free Spins can only be used on the Starburst slot.</li>
				<li>The Free Spins will have a value of £0.10 each.</li>
				<li>
					Free Spins shall be available to play with but winnings resulting from the Free Spin cannot be withdrawn
					until the Eligible Player meets the Free Spin Requirements (as defined below).
				</li>
				<li>
					The amount won using each Free Spin (“Winnings”) must be wagered ten (10) times before the Winnings can
					be withdrawn (“Free Spin Requirements”).
				</li>
				<li>The Free Spin Requirements must be fulfilled within 1 day (the “Time Limit”).</li>
				<li>
					The Winnings shall be lost and removed from the Eligible Players’ accounts if the Free Spin Requirements
					are not met within the Time Limit.
				</li>
				<li>Players can keep track of their Winnings in their player accounts.</li>
			</ol>
			<p>
				<strong>General</strong>
			</p>
			<ol start={18}>
				<li>
					Where the company suspects that a winning player is guilty of fraudulent activity, the company reserves
					the right to suspend or remove that player from the Promotion, and/or suspend that player’s account
					and/or suspend the Promotion pending further investigation.
				</li>
				<li>
					We reserve the right to alter, discontinue or terminate the promotion in accordance with the terms of our
					Standard Promotional Terms and Conditions:&nbsp;
					<a
						href='https://help.partycasino.com/en/general-information/legal-matters/general-terms-and-conditions/general-uk-and-row/standard-promotional-terms-and-conditions?promobanner=true&amp;productId=CASINO'
						target='_blank'
						rel='noreferrer'
					>
						https://help.partycasino.com/en/general-information/legal-matters/general-terms-and-conditions/general-uk-and-row/standard-promotional-terms-and-conditions
					</a>
					.
				</li>
				<li>
					We may place restrictions on your account, including deposit restrictions, in order to comply with our
					legal and regulatory obligations. We will not be responsible should these restrictions affect your
					ability to complete the requirements of this promotion and/or to release any bonus, benefits or prizes.
				</li>
			</ol>
		</div>
	);
};

export default Content;
